import LocalizedLink from "./LocalizedLink";

const GoToButton = ({ targetPage, label, align }) => {
  if (!targetPage || !label) return null;
  return (
    <div className={`text-${align || "center"} mt-4 mb-4`}>
      <LocalizedLink
        page={{ pageId: targetPage }}
        className="button button--primary button--big"
      >
        {label}
      </LocalizedLink>
    </div>
  );
};

export default GoToButton;
