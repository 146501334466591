import { gql, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import AssortmentFragment from "../fragment/AssortmentFragment";

export const RootCategories = gql`
  query RootCategories($locale: String, $tags: [LowerCaseString!]) {
    assortments(limit: 0, tags: $tags) {
      ...AssortmentFragment
    }
  }
  ${AssortmentFragment}
`;

const useCategories = ({ tags = null }) => {
  const intl = useIntl();
  const locale = intl.locale.split("-")[0];
  const { loading, error, data } = useQuery(RootCategories, {
    variables: {
      locale,
      tags,
    },
  });

  const cmsCategorys = data?.assortments || [];

  return {
    loading,
    error,
    categories: [...cmsCategorys],
  };
};

export const preFetchCategories = async (apolloClient, { locale }) => {
  const { data: { assortments = [] } = {} } = await apolloClient.query({
    query: RootCategories,
    variables: { locale, tags: null },
  });
  return assortments;
};
export default useCategories;
