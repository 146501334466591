import HorizontalDefinitionListItem from "modules/layout/components/HorizontalDefinitionListItem";
import React from "react";

const FAQList = ({ faqs }) => {
  return faqs.map((faq) => (
    <HorizontalDefinitionListItem
      key={faq._id}
      definition={faq.answer}
      label={faq.question}
    />
  ));
};

export default FAQList;
