import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";
import useReleases from "../hooks/useReleases";
import { useIntl } from "react-intl";
import Icon from "modules/common/components/Icon";
import ThemeTeaser from "modules/layout/components/ThemeTeasers";

export const ReleasesCard = ({ tag, title }) => {
  const { formatDate } = useIntl();
  const { releases, loading } = useReleases();
  const intl = useIntl();

  const releasesPageId = findPageIdByFilePath("/releases/:slug");
  const newestRelease = releases?.[0];

  if (loading) return <Icon icon="loading" />;
  if (!newestRelease) return null;

  return (
    <ThemeTeaser pageId={releasesPageId} title={title} label={tag}>
      <div className="px-3 py-1">
        <div className="mb-2">
          {intl.formatMessage({
            id: "last-release",
            defaultMessage: "Letzter Release:",
          })}
        </div>
        <div className="font-weight-600">{newestRelease.version}</div>
        {formatDate(new Date(newestRelease.date), {
          dateStyle: "medium",
          timeStyle: "short",
        })}
      </div>
    </ThemeTeaser>
  );
};

export default ReleasesCard;
