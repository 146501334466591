import { useQuery, gql } from "@apollo/client";

export const GET_CMS_SITEMAP_QUERY = gql`
  query cmsPages($locale: String) {
    pages(locale: $locale)
  }
`;

const useCMSSitemap = (locale: string) => {
  const { data } = useQuery(GET_CMS_SITEMAP_QUERY, {
    variables: {
      locale,
    },
  });

  return data?.pages || [];
};

export const preFetchSiteMap = async (apolloClient, { locale }) => {
  const { data } = await apolloClient.query({
    query: GET_CMS_SITEMAP_QUERY,
    variables: { locale },
  });
  return data?.pages;
};
export default useCMSSitemap;
