import { gql, useQuery } from "@apollo/client";

export const CurrentClientQuery = gql`
  query currentClient {
    me {
      _id
      username
      currentClient {
        _id
        name
        firstName
        lastName
        birthday
        postalCode
        city
        addressLine
        addressLine2
        isPrivateCustomer
        isInstitution
        contactNumber
        customerNumber
        companyNumber
        showMigelConformityInRecipes
        showMigelConformityInCatalog
        showEMSDepartment
        cart {
          _id
        }
        doctors {
          contactNumber
          contactName
          contactEmail
        }
      }
    }
  }
`;

const useCurrentClient = () => {
  const {
    data: { me } = {},
    loading,
    error,
  } = useQuery(CurrentClientQuery, {
    variables: {},
    partialRefetch: true,
  });

  return {
    me,
    currentClient: me && me.currentClient,
    loading,
    error,
  };
};

export default useCurrentClient;
