import { useIntl } from "react-intl";

const CompetenceDropdown = ({ onChange, value }) => {
  const intl = useIntl();
  return (
    <select className="form-field" value={value} onChange={onChange}>
      <option value="">
        {intl.formatMessage({
          id: "competence_team",
          defaultMessage: "Kompetenz-Team",
        })}
      </option>
      <option value="STOMAVERSORGUNG">
        {intl.formatMessage({
          id: "ostomy_care",
          defaultMessage: "Stomaversorgung",
        })}
      </option>
      <option value="TRACHEOSTOMAVERSORGUNG">
        {intl.formatMessage({
          id: "tracheo_ostomy_care",
          defaultMessage: "Tracheostomaversorgung",
        })}
      </option>
      <option value="INKONTINENZ">
        {intl.formatMessage({
          id: "incontinence",
          defaultMessage: "Inkontinenz",
        })}
      </option>
      <option value="WUNDBEHANDLUNG">
        {intl.formatMessage({
          id: "wound_treatment",
          defaultMessage: "Wundbehandlung",
        })}
      </option>
    </select>
  );
};

export default CompetenceDropdown;
