import { useState } from "react";
import { useIntl } from "react-intl";
import useHRDownloadable from "../hooks/useHRDownloadable";
import DownloadableLink from "../../layout/components/DownloadableLinkItem";
import TextField from "modules/forms/components/TextField";
import SubmitButton from "modules/forms/components/SubmitButton";
import useForm from "modules/forms/hooks/useForm";
import Form from "modules/forms/components/Form";
import FormErrors from "modules/forms/components/FormErrors";

const InternalDownloadable = ({ title, subtitle, ...rest }) => {
  const { formatMessage } = useIntl();
  const [downloadable, setDownloadable] = useState([]);
  const onSubmitSuccess = (data) => {
    setDownloadable(data);
    return true;
  };
  const getFiles = async (values) => {
    const { userInput } = values;
    const downloadables = await getDownloadable({ code: userInput });
    setDownloadable(downloadables);
    if (downloadable?.length) return { success: true, data: downloadable };
    return { success: false };
  };

  const form = useForm({
    submit: getFiles,
    onSubmitSuccess,
    initialValues: {
      userInput: null,
    },
    getSubmitErrorMessage: (e) => {
      return formatMessage({
        id: "invalid-code",
        defaultMessage: "The code you input is not valid",
      });
    },
  });

  const { getDownloadable } = useHRDownloadable();

  return !downloadable?.length ? (
    <div className="br-9 color-bg-blue-light p-3 box-shadow">
      <h3 className="mt-0 mb-2">{title}</h3>
      <div className="mb-3">{subtitle}</div>
      <Form form={form}>
        <TextField
          name="userInput"
          label={formatMessage({
            id: "your_code",
            defaultMessage: "Ihr Zugangscode",
          })}
          className="code-input"
          required
          type="text"
          placeholder="000000"
        />
        <FormErrors />
        <SubmitButton
          className="button button--secondary mt-3"
          label={formatMessage({
            id: "send_code",
            defaultMessage: "Code senden",
          })}
        />
      </Form>
    </div>
  ) : (
    <div className="mt-4">
      <div className="bordered-top">
        {downloadable?.map((download) => (
          <DownloadableLink
            key={download._id}
            document={download.file}
            title={download.title}
          />
        ))}
      </div>
    </div>
  );
};

export default InternalDownloadable;
