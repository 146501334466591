import { useQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";

export const GET_TEAM_QUERY = gql`
  query cmsGetEmployees($locale: String, $filter: JsonType) {
    employeesModel(locale: $locale, filter: $filter) {
      _id
      image
      tel
      email
      name
      title
      order
    }
  }
`;

const useCMSTeam = ({ competence, department }) => {
  const intl = useIntl();
  const locale = intl.locale.split("-")[0];

  const variables: Record<string, any> = { locale, filter: {} };
  if (competence) variables.filter.competence = competence;
  if (department) variables.filter.department = department;

  const { data, loading, error } = useQuery(GET_TEAM_QUERY, {
    variables,
  });
  return {
    loading,
    error,
    team: [...(data?.employeesModel || [])]?.sort(
      ({ order: aOrder }, { order: bOrder }) => bOrder - aOrder,
    ),
  };
};

export default useCMSTeam;
