// utils/cmsUtils.js
import getConfig from "next/config";

const {
  publicRuntimeConfig: { cmsRouteToPageIdMap },
} = getConfig();

type CmsRouteToPageIdMap = {
  [key: string]: string;
};

export const findPageIdByFilePath = (routePattern: string): string | null => {
  const map: CmsRouteToPageIdMap = cmsRouteToPageIdMap as CmsRouteToPageIdMap;

  const [pageIdEntry] = Object.entries(map).find(([, route]) =>
    route.includes(routePattern),
  );

  return pageIdEntry;
};
