import React from "react";
import { useIntl } from "react-intl";

import EventListItem from "./EventListItem";
import useCMSEvents from "../hooks/useCMSEvents";
import Loading from "../../layout/components/Loading";

const EventList = ({ limit = 0, type = "simple", categoryId = "" }) => {
  const intl = useIntl();
  const { events, loading } = useCMSEvents({ limit, categoryId });

  const typeClassMap = {
    simple: "col-md-6 mb-4",
    homepage: "col-md-4 mb-4 mb-md-3",
    storiespage: "col-md-4 mb-4",
  };

  if (loading) return <Loading />;

  if (events.length === 0) {
    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: "empty_events",
            defaultMessage:
              "Die nächsten Veranstaltungen werden demnächst auf unserer Website publiziert.",
          }),
        }}
      />
    );
  }

  return (
    <div className="row">
      {events.map((entry) => (
        <EventListItem
          key={entry._id}
          className={typeClassMap[type]}
          event={entry}
        />
      ))}
    </div>
  );
};

export default EventList;
