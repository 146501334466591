import classNames from "classnames";

const TdTag = ({ children, className = "", ...props }) => {
  return (
    <td className={classNames("table-cell", className)} {...props}>
      <div className="d-flex justify-content-start align-items-center">
        <span className="icon-label">{children}</span>
      </div>
    </td>
  );
};

export default TdTag;
