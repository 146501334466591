import React from "react";
import LocalizedLink from "./LocalizedLink";
import ImageWithFallback from "modules/common/components/ImageWithFallback";

const ThemeTeaser = ({
  pageId,
  title,
  image,
  label,
  children,
}: {
  pageId: string;
  title: string;
  image?: any;
  label: string;
  children: any;
}) => {
  return (
    <LocalizedLink page={{ pageId }}>
      <div className="h-100 br-9 py-3 d-flex flex-column theme-teaser box-shadow justify-content-start">
        {label && (
          <div className="px-3 color-green-dark font-size-inbetween">
            {label}
          </div>
        )}
        <h3
          className="px-3 pb-1 my-2"
          dangerouslySetInnerHTML={{ __html: title || "n/a" }}
        />
        {image && (
          <div>
            <ImageWithFallback
              className="force-position-relative"
              src={image?.path}
              alt={image?.title}
              quality={80}
              fill
              sizes="100vw"
              style={{
                objectFit: "contain",
              }}
            />
          </div>
        )}
        {children}
      </div>
    </LocalizedLink>
  );
};

export default ThemeTeaser;
