import Icon from "modules/common/components/Icon";
import useCMSMaintenance from "../hooks/useCMSMaintenance";
import MaintenanceItem from "./MaintenanceItem";
import { useIntl } from "react-intl";

export const MaintenanceSection = ({ title }) => {
  const { maintenance: items, loading } = useCMSMaintenance();
  const { formatMessage } = useIntl();
  if (loading) return <Icon icon="loading" />;

  return (
    <>
      <h3 className="font-weight-bold mb-3">{title}</h3>
      <div className="font-size-1">
        <div className="position-relative">
          {items?.length
            ? items?.map(({ from, to, text, _id }) => (
                <MaintenanceItem key={_id} text={text} from={from} to={to} />
              ))
            : formatMessage({
                id: "no-maintenance-scheduled",
                defaultMessage: "Keine Wartungsarbeiten geplant",
              })}
        </div>
      </div>
    </>
  );
};

export default MaintenanceSection;
