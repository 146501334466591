import Icon from "modules/common/components/Icon";
import useCMSSystemStatusByIds from "../hooks/useCMSSystemStatusByIds";
import { useIntl } from "react-intl";

export const SystemStatusBanner = () => {
  const { loading, status: items } = useCMSSystemStatusByIds();
  const { formatMessage, formatDate } = useIntl();

  if (loading) return <Icon icon="loading" />;

  const everythingOperational = items?.every(
    ({ status }) => status === "operational",
  );

  const divWrapperClassName = everythingOperational
    ? "color-bg-green-lightest color-green-dark"
    : "color-bg-orange-lightest color-orange-dark";

  return (
    <div
      className={`d-flex align-items-center flex-wrap justify-content-between p-4 ${divWrapperClassName}`}
      style={{ border: "1px solid" }}
    >
      <div className="font-weight-bold mr-3">
        {everythingOperational
          ? formatMessage({
              id: "all-operational",
              defaultMessage: "Alle Systeme operativ",
            })
          : formatMessage({
              id: "not-all-operational",
              defaultMessage: "Mindestens ein System nicht voll operativ.",
            })}
      </div>
      <small>
        {formatMessage(
          {
            id: "status-last-updated",
            defaultMessage: "Zuletzt aktualisiert: {updatedDate}",
          },
          {
            updatedDate: formatDate(new Date(), {
              dateStyle: "medium",
            }),
          },
        )}
      </small>
    </div>
  );
};

export default SystemStatusBanner;
