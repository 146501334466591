import classnames from "classnames";

import useField, { FieldHookProps } from "../hooks/useField";
import FieldWrapper from "./FieldWrapper";

export interface SelectFieldOptions {
  // eslint-disable-next-line no-undef
  [key: string]: string | JSX.Element;
}

interface SelectFieldProps extends FieldHookProps {
  options: SelectFieldOptions;
}

const SelectField = (props: SelectFieldProps) => {
  const { options, ...field } = useField(props);

  return (
    <FieldWrapper {...field}>
      <div className="select-wrap">
        <select
          className={classnames("form-field", {
            "form-field--error": !!field.error,
          })}
          disabled={field.disabled}
          id={field.name}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
        >
          {(!!field.placeholder || !field.required) && (
            <option value="" disabled={field.required} hidden={field.required}>
              {field.placeholder || field.label}
            </option>
          )}
          {Object.entries(options).map(([value, display]) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </select>
      </div>
    </FieldWrapper>
  );
};

export default SelectField;
