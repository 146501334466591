import { useIntl } from "react-intl";

const DepartmentDropdown = ({ onChange, value }) => {
  const intl = useIntl();
  return (
    <select className="form-field" value={value} onChange={onChange}>
      <option value="">
        {intl.formatMessage({
          id: "department",
          defaultMessage: "Abteilung",
        })}
      </option>
      <option value="LAGER_EINKAUF_VERSAND">
        {intl.formatMessage({
          id: "department_warehouse",
          defaultMessage: "Einkauf | Logistik",
        })}
      </option>
      <option value="AUSSENDIENST">
        {intl.formatMessage({
          id: "department_field_services",
          defaultMessage: "Aussendienst",
        })}
      </option>
      <option value="GESCHAEFTSLEITUNG">
        {intl.formatMessage({
          id: "department_management",
          defaultMessage: "Geschäftsleitung",
        })}
      </option>
      <option value="HUMAN_RESOURCES">
        {intl.formatMessage({
          id: "department_hr",
          defaultMessage: "Human Resources",
        })}
      </option>
      <option value="FINANZEN_INFORMATIK_PROZESSE_QM">
        {intl.formatMessage({
          id: "department_finances",
          defaultMessage:
            "Finanzen | Informatik | Prozesse & Qualitätsmanagement",
        })}
      </option>
      <option value="KUNDENDIENST">
        {intl.formatMessage({
          id: "department_client_services",
          defaultMessage: "Kundendienst",
        })}
      </option>
      <option value="MARKETING_KOMMUNIKATION">
        {intl.formatMessage({
          id: "department_marketing",
          defaultMessage: "Marketing & Kommunikation",
        })}
      </option>
    </select>
  );
};

export default DepartmentDropdown;
