import React, { useState } from "react";

import { useIntl } from "react-intl";

import Icon from "../../common/components/Icon";
import CallBackForm from "./CallBackForm";
import Modal from "../../modal/components/Modal";

const AppointmentCallToAction = ({ title, subtitle }) => {
  const intl = useIntl();
  const [contactFormVisible, setContactFormVisible] = useState(false);
  const toggleContactForm = () => setContactFormVisible(!contactFormVisible);
  return (
    <div className="call-to-action-area">
      <Modal
        visible={contactFormVisible}
        onClose={() => setContactFormVisible(false)}
        disableOkButton
      >
        <CallBackForm visible onHide={() => setContactFormVisible(false)} />
      </Modal>
      <h3>{title}</h3>
      <p>{subtitle}</p>
      <button
        type="button"
        className="button button--primary"
        onClick={toggleContactForm}
      >
        {intl.formatMessage({
          id: "appointment_call_button",
          defaultMessage: "Jetzt online vereinbaren",
        })}
        <Icon icon="Pfeil-rechts" fill="currentColor" className="ml-2" />
      </button>
    </div>
  );
};
export default AppointmentCallToAction;
