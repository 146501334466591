const convertToTwoDigit = (value) =>
  Number(value) < 10 ? `0${value}` : +value;
const useGetToday = () => {
  const getZurichTime = () => {
    const now = new Date();
    const zurichTime = new Date(
      now.toLocaleString("en-US", { timeZone: "Europe/Zurich" }),
    );

    const year = zurichTime.getFullYear();
    const month = convertToTwoDigit(zurichTime.getMonth() + 1); // Note: Month is zero-based
    const day = convertToTwoDigit(zurichTime.getDate());
    const hour = convertToTwoDigit(zurichTime.getHours());
    const minute = convertToTwoDigit(zurichTime.getMinutes());
    const second = convertToTwoDigit(zurichTime.getSeconds());

    return {
      year,
      month,
      day,
      hour,
      minute,
      second,
    };
  };

  const today = () => {
    const { year, month, day, hour, minute } = getZurichTime();

    return `${year}-${month}-${day} ${hour}:${minute}`;
  };

  return { today, getZurichTime };
};

export default useGetToday;
