import React, { useState } from "react";
import dynamic from "next/dynamic";
import RoutesContext from "../RoutesContext";
import ModalWrapper from "../../modal/components/ModalWrapper";
import useCMSInfoText from "modules/layout/hooks/useCMSInfoText";
import publicareFonts from "modules/common/fonts/publicareFonts";
import useCurrentUser from "modules/auth/hooks/useCurrentUser";

const InfoText = dynamic(() => import("../../layout/components/InfoText"), {
  ssr: false,
});

const CurrentPageWrapper = ({
  component: Component,
  locale,
  routes,
  ...props
}) => {
  const [bodyOverflowHidden, setBodyOverflowHidden] = useState(false);
  const [isCartToggled, setCartToggled] = useState(false);
  const [isClientChooserToggled, setUserToggled] = useState(false);
  const { currentUser } = useCurrentUser();
  const { messages } = useCMSInfoText();

  return (
    <RoutesContext.Provider
      value={{ // eslint-disable-line
        isCartToggled,
        setCartToggled,
        isClientChooserToggled,
        setUserToggled,
        bodyOverflowHidden,
        setBodyOverflowHidden,
      }}
    >
      <main className={publicareFonts.className}>
        <ModalWrapper>
          <InfoText messages={messages} userId={currentUser?._id} />
          <Component {...props} />
          {/* eslint-disable-next-line */}
        <style jsx global>
            {`
              @media (max-width: 1023px) {
                body {
                  ${bodyOverflowHidden ? "overflow: hidden;" : ""}
                }
              }
            `}
          </style>
        </ModalWrapper>
      </main>
    </RoutesContext.Provider>
  );
};

export default CurrentPageWrapper;
