import { useState, useMemo } from "react";

import { ToastContext } from "../hooks/useToast";

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const displayToast = (toast: { type: string; meta: any }) => {
    const { type, meta } = toast;
    setToasts([{ type, ...meta }]);
  };

  const ctx = useMemo(() => ({ toasts, displayToast }), [toasts]);

  return <ToastContext.Provider value={ctx}>{children}</ToastContext.Provider>;
};

export default ToastProvider;
