import { useQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";

export const GET_EMPLOYEE_QUERY = gql`
  query cmsGetEmployee($locale: String, $filter: JsonType) {
    employeesModel(locale: $locale, filter: $filter) {
      _id
      image
      tel
      email
      name
      title
    }
  }
`;

const useCMSEmployee = ({ employeeId }) => {
  const intl = useIntl();
  const locale = intl.locale.split("-")[0];

  const variables: Record<string, any> = {
    locale,
    filter: {
      _id: employeeId,
    },
  };

  const { data, loading, error } = useQuery(GET_EMPLOYEE_QUERY, {
    variables,
  });

  return {
    loading,
    error,
    employee: data?.employeesModel?.[0],
  };
};

export default useCMSEmployee;
