import React from "react";

const RoutesContext = React.createContext({
  isCartToggled: false,
  // eslint-disable-next-line
  setCartToggled: (bool) => {},
  isClientChooserToggled: true,
  // eslint-disable-next-line
  setUserToggled: (bool) => {},
  bodyOverflowHidden: false,
  setBodyOverflowHidden: (any) => {},
});

export default RoutesContext;
