import classNames from "classnames";
import LayoutRenderer from "../LayoutRenderer";

const GridComponent = ({ data, columns }) => {
  const additionalColSizeClass = data?.colWidth && `col-md-${data.colWidth}`;
  return (
    <div className={classNames(data.class, additionalColSizeClass, "")}>
      <div className="row">
        {columns.map((column, colIndex) => (
          <div
            key={colIndex}
            className={classNames(
              "col-md mb-5 mb-md-0",
              additionalColSizeClass,
            )}
          >
            <LayoutRenderer layout={column.components} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridComponent;
