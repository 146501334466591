import { useIntl } from "react-intl";
import {
  useField as useFormikField,
  FormikHandlers,
  FieldHelperProps,
  FormikState,
} from "formik";

import { Validator, validateRequired } from "../utils/validators";
import useFormContext from "./useFormContext";

export interface CommonFieldProps {
  name: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  errorMessage?: string;
  help?: string;
  className?: string;
  placeholder?: string;
  half?: boolean;
  third?: boolean;
  /**
   * A second change handler without the possibility to change the value.
   */
  onChange?: FormikHandlers["handleChange"];
  [key: string]: any;
}

export interface FieldHookProps extends CommonFieldProps {
  validators?: Validator[];
}

export interface ComputedProps extends CommonFieldProps {
  error?: string;
  onChange: FormikHandlers["handleChange"];
  onBlur: FormikHandlers["handleBlur"];
  setValue: FieldHelperProps<any>["setValue"];
  value: string; // | string[]
}

const useField = (props: FieldHookProps): ComputedProps => {
  const intl = useIntl();

  const { formik, ...form } = useFormContext();

  const {
    name,
    required,
    errorMessage,
    disabled = form.disabled || (formik as any).isSubmitting,
    // By default, the label is the translated field name
    label = intl.formatMessage({
      id: name,
      defaultMessage: name,
      description: "SKIP TRANSLATION: as the value can not be pre-determined",
    }),
    validators = [],
  } = props;

  const combinedValidators = [
    ...validators,
    required && validateRequired,
  ].filter(Boolean);

  const validate = (value) =>
    combinedValidators.reduce(
      (acc: string, { isValid, intlMessageDescriptor, intlMessageValues }) => {
        if (acc) return acc;

        // Do not run validators if field is not required
        if (!required && !value) return acc;

        if (!isValid(value))
          return (
            errorMessage ||
            intl.formatMessage(intlMessageDescriptor, {
              label,
              ...intlMessageValues,
            })
          );
        return acc;
      },
      "",
    );

  const [field, meta, helpers] = useFormikField({
    name,
    validate,
  });

  const error =
    (meta.touched || (formik as FormikState<any>).submitCount > 0) &&
    meta.error;

  const onChange = (event) => {
    if (props.onChange) {
      props.onChange(event);
    }
    field.onChange(event);
  };

  return {
    ...props,
    name,
    error,
    label,
    disabled,
    required,
    onChange,
    onBlur: field.onBlur,
    value: field.value || "",
    setValue: helpers.setValue,
  };
};

export default useField;
