import Icon from "modules/common/components/Icon";
import ImageWithFallback from "modules/common/components/ImageWithFallback";
import { useState } from "react";

import { useIntl } from "react-intl";
import LocalizedLink, { useLazyLocalizedRoute } from "./LocalizedLink";
import normalizeDateFormat from "modules/common/utils/normalizeCockpitDateField";
import useCMSPageById from "modules/navigation/hooks/useCMSPageById";
import Loading from "./Loading";

const PageContentItem = ({ item, route }) => {
  return (
    <div className="col-md-6 mb-4">
      <LocalizedLink
        page={{ pageId: route }}
        className="br-9 h-100 box-block box-shadow mb-4 d-flex flex-column color-bg-white"
      >
        <div className="overflow-hidden position-relative box-image-wrap">
          <ImageWithFallback
            src={item?.image?.path}
            loading="lazy"
            alt="product-category-image"
            quality={80}
            className="box-image box-shadow min-width-auto min-height-auto"
            sizes="100vw"
            fill
            style={{
              objectFit: "cover",
            }}
          />
        </div>
        <h4 className="flex-grow-1 my-0 pt-3 px-3 font-weight-bold">
          {item.title}
        </h4>
        <div className="d-flex justify-content-between align-items-top px-3 pb-3">
          <div className="opacity-78">
            {item?.publicationDate
              ? normalizeDateFormat("publicationDate")(item)?.publicationDate
              : null}
          </div>
        </div>
      </LocalizedLink>
    </div>
  );
};

const PageContentTeaser = ({ pageId, limit, header, forceLocale = null }) => {
  const { locale: defaultLocale, formatMessage } = useIntl();
  const normalizedLocale = forceLocale || defaultLocale;

  const { getCMSPage } = useLazyLocalizedRoute({ forceLocale });
  const [displayLimit, setLimit] = useState(limit || 2);
  let normalizedPageId = null;

  if (typeof pageId === "string") {
    normalizedPageId = pageId;
  } else if (typeof pageId === "object" && pageId?.route) {
    normalizedPageId = pageId.route;
  }
  const pageInfo = getCMSPage(normalizedPageId);
  const { page, loading } = useCMSPageById({
    pageId: pageInfo?._id,
    locale: normalizedLocale,
  });
  if (loading) return <Loading />;
  if (!pageInfo) return null;

  const { data } = page || {};

  return (
    <div className="mt-5">
      <div className="teaser-head">
        <h3 className="teaser-head__title">{header}</h3>
        <LocalizedLink
          page={{ pageId: page?._id }}
          locale={normalizedLocale}
          className="button button--secondary"
        >
          <>
            {formatMessage({ id: "all", defaultMessage: "Alle" })}
            <Icon
              icon="Pfeil-rechts"
              fill="currentColor"
              className="ml-2 mr-0"
            />
          </>
        </LocalizedLink>
      </div>
      <div className="row">
        {(data?.items || [])
          .slice(0, displayLimit)
          ?.map((item) => (
            <PageContentItem
              pageId={normalizedPageId}
              key={item?.item._id}
              {...item}
            />
          ))}
      </div>
      {!loading && displayLimit < (data?.items || [])?.length && (
        <div className="text-center py-4">
          <button
            aria-label={formatMessage({
              id: "load_more",
              defaultMessage: "Weitere laden ...",
            })}
            type="button"
            className="button button--primary button--big"
            onClick={() => setLimit(displayLimit + 2)}
          >
            <Icon
              icon="Pfeil-nach-unten"
              className="mr-2"
              fill="currentColor"
            />
            {formatMessage({
              id: "load_more",
              defaultMessage: "Weitere laden ...",
            })}
          </button>
        </div>
      )}
    </div>
  );
};

export default PageContentTeaser;
