import React from "react";
import { useIntl } from "react-intl";
import useCMSPageById from "modules/navigation/hooks/useCMSPageById";
import ThemeTeaser from "./ThemeTeasers";

const PageTeaserCard = ({ uri }) => {
  const intl = useIntl();
  const [, pageId] = uri.split("://");
  const [normalizedPageId] = pageId.split("?");
  const { page, loading } = useCMSPageById({
    pageId: normalizedPageId,
    locale: intl.locale,
  });

  const { seo, title } = page || {};

  if (loading) {
    return <div className="col-md-6 mb-3">Loading</div>;
  }

  return (
    <div className="row mt-5">
      <div className="col-md-6 mb-4">
        <ThemeTeaser {...seo} pageId={normalizedPageId} label={title}>
          <p
            className="px-3 py-1"
            dangerouslySetInnerHTML={{ __html: seo.description }}
          />
        </ThemeTeaser>
      </div>
    </div>
  );
};

export default PageTeaserCard;
