/* eslint-disable import/no-cycle */
import { useRouter } from "next/router";
import { useContext } from "react";
import { CockpitContext } from "./CockpitContext";

const LayoutRenderer = ({
  layout,
  showTableOfContent,
  ...rest
}: { layout: any; showTableOfContent?: boolean } & Record<string, any>) => {
  const layoutComponentMap = useContext(CockpitContext);
  const router = useRouter();

  return layout?.map((layoutComponent, index) => {
    const { component, hidden } = layoutComponent;

    if (hidden) return null;
    const Component = layoutComponentMap?.[component];
    if (!Component) {
      console.warn(`Unknown component type: ${component}`);
      return null;
    }
    return (
      <Component
        key={`${component}-${index}-${rest?.currentRoute || router?.asPath}`}
        {...layoutComponent}
        {...rest}
        showTableOfContent={showTableOfContent}
      />
    );
  });
};

export default LayoutRenderer;
