import { gql, useQuery } from "@apollo/client";
import { initializeApollo, useApollo } from "modules/apollo/apolloClient";
import { useIntl } from "react-intl";

export const GET_INTERNAL_DOWNLOADS = gql`
  query HRDownloads($filter: JsonType, $locale: String) {
    downloadsModel(filter: $filter, locale: $locale) {
      _id
      title
      file
      service
    }
  }
`;

const GETCOCKPIT_DOWNLOAD_LANGUAGE_MAP = {
  de: "Deutsch",
  fr: "Französisch",
  it: "Italienisch",
};

const useHRDownloadable = () => {
  const { locale } = useIntl();
  const apollo = initializeApollo(null, { locale });
  const getDownloadable = async ({ code }) => {
    let filter: any = {};

    const languageFilterField = locale === "de" ? "file" : `file_${locale}`;
    filter = {
      code,
      $or: [{ service: "HR-Downloads" }],
      $and: [
        { [languageFilterField]: { $ne: null } },
        { [languageFilterField]: { $ne: "" } },
        { language: GETCOCKPIT_DOWNLOAD_LANGUAGE_MAP[locale] },
      ],
    };

    const { data } = await apollo.query({
      skip: !code,
      query: GET_INTERNAL_DOWNLOADS,
      variables: { filter, locale },
    });

    return data?.downloadsModel || [];
  };

  return {
    getDownloadable,
  };
};

export default useHRDownloadable;
