import React, { useState } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";
import { isWeekend, setHours, setMinutes } from "date-fns";

import EmailField from "../../forms/components/EmailField";
import TextField from "../../forms/components/TextField";
import SelectField from "../../forms/components/SelectField";
import DatePickerField from "../../forms/components/DatePickerField";
import SubmitButton from "../../forms/components/SubmitButton";
import Form from "../../forms/components/Form";
import useSendContactForm from "../hooks/useSendContactForm";
import FormErrors from "../../forms/components/FormErrors";
import useForm from "../../forms/hooks/useForm";
import Icon from "../../common/components/Icon";
import TextAreaField from "../../forms/components/TextAreaField";

const CallBackForm = ({ visible, className = "call-back-form" }) => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [sendContactForm] = useSendContactForm("CALLBACK");
  const intl = useIntl();
  const form = useForm({
    submit: sendContactForm,
    onSubmitSuccess: () => {
      setIsSuccessfullySubmitted(true);
    },
    getSubmitErrorMessage: (error) => {
      if (error?.message?.includes("auth failed")) {
        return intl.formatMessage({
          id: "error_send_contact_form",
          defaultMessage:
            "Kontaktanfrage fehlgeschlagen, bitte Senden Sie uns eine E-Mail",
        });
      }
      return "";
    },
    initialValues: {
      gender: "Unbestimmt",
      company: null,
      fullName: null,
      phoneNumber: null,
      emailAddress: null,
      topic: "Allgemein",
      date: null,
      message: null,
    },
  });
  if (isSuccessfullySubmitted) {
    return (
      <h2 className="text-center">
        <Icon icon="email-action-check icon--bigger" fill="currentColor" />
        <div>
          {intl.formatMessage({
            id: "appointment_call_thank_you",
            defaultMessage:
              "Vielen Dank für Ihre Anfrage! Wir werden Sie sobald wie möglich kontaktieren.",
          })}
        </div>
      </h2>
    );
  }
  return (
    <div className={classnames({ "is-active": visible }, className)}>
      <h3 className="text-center mt-0">
        {intl.formatMessage({
          id: "appointment_call_title",
          defaultMessage: "Vereinbaren Sie einen Beratungstermin",
        })}
      </h3>
      <div className="text-center mx-auto mb-5 call-back-lead">
        <h4>
          {intl.formatMessage({
            id: "appointment_call_text",
            defaultMessage:
              "Vereinbaren Sie jetzt einen Termin zur Beratung rund um unsere Dienstleistungen und Produkte.",
          })}
        </h4>
      </div>
      <Form className="form mx-auto" form={form}>
        <div className="wrap-field-container--half">
          <SelectField
            half
            required
            label={intl.formatMessage({
              id: "gender",
              defaultMessage: "Anrede",
            })}
            name="gender"
            options={{
              Unbestimmt: intl.formatMessage({
                id: "gender",
                defaultMessage: "Anrede",
              }),
              Herr: intl.formatMessage({
                id: "gender_m",
                defaultMessage: "Herr",
              }),
              Frau: intl.formatMessage({
                id: "gender_f",
                defaultMessage: "Frau",
              }),
            }}
          />
          <TextField
            half
            name="company"
            label={intl.formatMessage({
              id: "company",
              defaultMessage: "Name Institution",
            })}
          />
        </div>

        <div className="wrap-field-container">
          <TextField
            required
            name="fullName"
            label={intl.formatMessage({ id: "name", defaultMessage: "Name" })}
          />
        </div>

        <div className="wrap-field-container--half">
          <TextField
            half
            required
            name="phoneNumber"
            label={intl.formatMessage({
              id: "telephoneNumber",
              defaultMessage: "Telefonnummer",
            })}
          />

          <EmailField
            required
            half
            name="emailAddress"
            label={intl.formatMessage({
              id: "email_address",
              defaultMessage: "E-Mail Adresse",
            })}
          />
        </div>

        <div className="wrap-field-container--half">
          <SelectField
            half
            required
            label={intl.formatMessage({ id: "topic", defaultMessage: "Thema" })}
            name="topic"
            options={{
              Allgemein: intl.formatMessage({
                id: "topic_general",
                defaultMessage: "Allgemein",
              }),
              Inkontinenz: intl.formatMessage({
                id: "incontinence",
                defaultMessage: "Inkontinenz",
              }),
              Stomaversorgung: intl.formatMessage({
                id: "ostomy_care",
                defaultMessage: "Stomaversorgung",
              }),
              Tracheostomaversorgung: intl.formatMessage({
                id: "tracheo_ostomy_care",
                defaultMessage: "Tracheostomaversorgung",
              }),
              Wundbehandlung: intl.formatMessage({
                id: "utilization_wund",
                defaultMessage: "Wundbehandlung",
              }),
            }}
          />
          <DatePickerField
            half
            label={intl.formatMessage({
              id: "contact_date",
              defaultMessage: "Termin",
            })}
            name="date"
            placeholder="__.__.____"
            parseDate={(date) => date.getTime()}
            datePickerOptions={{
              openToDate: setMinutes(new Date(), 30),
              minDate: new Date(),
              showTimeSelect: true,
              minTime: setHours(setMinutes(new Date(), 0), 7),
              maxTime: setHours(setMinutes(new Date(), 0), 17),
              filterDate: (date) => !isWeekend(date),
              dateFormat: "dd.MM.yyyy HH:mm",
            }}
          />
        </div>

        <div className="wrap-field-container">
          <TextAreaField
            name="message"
            rows={3}
            label={intl.formatMessage({
              id: "message",
              defaultMessage: "Nachricht",
            })}
          />
        </div>

        <FormErrors />

        <SubmitButton
          label={intl.formatMessage({ id: "submit", defaultMessage: "Senden" })}
        />
      </Form>
    </div>
  );
};
export default CallBackForm;
