import React from "react";
import { useScrollPosition } from "../../navigation/components/ScrollPositionProvider";
import LocalizedLink from "../../layout/components/LocalizedLink";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";
import ImageWithFallback from "modules/common/components/ImageWithFallback";

const StoryListItem = ({ className = "", story }) => {
  const { triggerScroll } = useScrollPosition();
  const storiesPageId = findPageIdByFilePath("/stories/:slug");

  return (
    <div className={className}>
      <LocalizedLink
        page={{
          pageId: storiesPageId,
          pathname: story.slug,
        }}
        className="br-9 h-100 box-block box-shadow mb-4 d-flex flex-column color-bg-white"
      >
        <div className="overflow-hidden position-relative box-image-wrap">
          <ImageWithFallback
            className="box-image"
            src={
              story?.image?.path
                ? story?.image?.path
                : "/static/img/no-image.png"
            }
            alt={story.title}
            onLoad={triggerScroll}
            quality={80}
            layout="fill"
            sizes="100vw"
          />
        </div>
        <h4
          className="flex-grow-1 my-0 pt-3 px-3 font-weight-bold"
          dangerouslySetInnerHTML={{ __html: story.title }}
        />
        <div className="d-flex justify-content-between align-items-top px-3 pb-3">
          <div className="opacity-78">{story.publicationDate}</div>
        </div>
      </LocalizedLink>
    </div>
  );
};

export default StoryListItem;
