import { gql } from "@apollo/client";

const AssortmentFragment = gql`
  fragment AssortmentFragment on Assortment {
    _id
    tags
    texts(forceLocale: $locale) {
      _id
      title
      subtitle
      description
      slug
      locale
    }
    media {
      _id
      file {
        strip: url(version: "STRIP")
      }
    }
  }
`;

export default AssortmentFragment;
