import Icon from "modules/common/components/Icon";
import DOMRenderer from "../../layout/components/DOMRenderer";
import LocalizedLink from "../../layout/components/LocalizedLink";
import { useIntl } from "react-intl";

const SystemStatusItem = ({ title, text, link, status, linkText = "" }) => {
  const intl = useIntl();
  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-1">
          <div className={`bullet bullet--${status} rounded-circle`}></div>
        </div>
        <div className="col-7">{title}</div>
        {link && (
          <LocalizedLink page={{ pageId: link }} className="link col-4">
            {linkText ||
              intl.formatMessage({
                id: "incident-support",
                defaultMessage: "Support",
              })}
            <Icon
              icon="arrow-button-right"
              className="icon--small ml-2"
              fill="currentColor"
            />
          </LocalizedLink>
        )}
      </div>
      <div className="offset-1">
        <DOMRenderer htmlString={text} />
      </div>
    </div>
  );
};

export default SystemStatusItem;
