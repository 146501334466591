const PublicareMapIFrame = () => {
  return (
    <iframe
      style={{ border: "0" }}
      tabIndex={0}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2699.281455028615!2d8.306979316445045!3d47.42595497917307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479013411c128ec3%3A0x9aece483073d506b!2sPublicare%20AG!5e0!3m2!1sen!2sch!4v1600417532292!5m2!1sen!2sch"
      width="100%"
      height="450"
      frameBorder={0}
      allowFullScreen={true}
      aria-hidden="false"
    ></iframe>
  );
};

export default PublicareMapIFrame;
