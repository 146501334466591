import React from "react";
import { useIntl } from "react-intl";
import LocalizedLink from "./LocalizedLink";
import Icon from "modules/common/components/Icon";

const CallToActionTeaser = ({
  title,
  subtitle,
  link,
  linkText = null,
  forceLocale = null,
}) => {
  const intl = useIntl();

  let normalizedLink = null;
  const normalizedLocale = forceLocale || intl.locale;
  if (typeof link === "string") normalizedLink = link;
  else if (typeof link === "object" && link?.route) normalizedLink = link.route;

  return (
    <div className="call-to-action-area">
      <h2>{title}</h2>
      <p>{subtitle}</p>
      <LocalizedLink
        page={{ pageId: normalizedLink }}
        locale={normalizedLocale}
        className="button button--primary"
      >
        {linkText ||
          intl.formatMessage({
            id: "appointment_call_button",
            defaultMessage: "Jetzt online vereinbaren",
          })}
        <Icon icon="Pfeil-rechts" fill="currentColor" className="ml-2" />
      </LocalizedLink>
    </div>
  );
};

export default CallToActionTeaser;
