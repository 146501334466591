const ButtonComponent = ({ data }) => {
  return (
    <a
      role="button"
      className={`my-3 button button--primary ${data?.class || ""}`}
      href={data?.url}
      target={data?.target || ""}
      aria-label={data?.caption}
    >
      {data?.caption}
    </a>
  );
};

export default ButtonComponent;
