import Icon from "modules/common/components/Icon";
import useCMSSystemStatusByIds from "../hooks/useCMSSystemStatusByIds";
import SystemStatusItem from "./SystemStatusItem";

export const SystemStatusSection = ({ systemStatusIds, title }) => {
  const { status: items, loading } = useCMSSystemStatusByIds(systemStatusIds);
  if (loading) return <Icon icon="loading" />;
  return (
    <>
      <h3 className="font-weight-bold mb-3">{title}</h3>
      <div className="font-size-1 mb-5">
        <div className="position-relative">
          {items?.map(({ title, text, link, linkText, status, _id }) => (
            <SystemStatusItem
              key={_id}
              title={title}
              link={link}
              text={text}
              status={status}
              linkText={linkText}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SystemStatusSection;
