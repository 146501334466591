import CategoryList from "./CategoryList";
import { useState } from "react";
import CallBackForm from "../../layout/components/CallBackForm";
import Modal from "../../modal/components/Modal";
import ImageWithFallback from "modules/common/components/ImageWithFallback";

const CategoryTeaserCards = ({
  title,
  placeholder,
  tags = ["visible-on-home"],
}) => {
  const [contactFormVisible, setContactFormVisible] = useState(false);
  const toggleContactForm = () => setContactFormVisible(!contactFormVisible);

  return (
    <div className="mt-5 pt-md-1">
      <Modal
        visible={contactFormVisible}
        onClose={() => setContactFormVisible(false)}
        disableOkButton
      >
        <CallBackForm visible onHide={() => setContactFormVisible(false)} />
      </Modal>
      <h3>{title}</h3>
      <CategoryList tags={tags}>
        <div className="col-md-6 col-lg-4 hero-product-col">
          <button
            type="button"
            onClick={toggleContactForm}
            className="no-button d-block box-shadow box-block br-9 w-100"
          >
            <div className="py-3 px-3 color-bg-blue text-center h-100 w-100 overflow-revert hero-contact-image-wrapper overide-default-image-properties br-9">
              <ImageWithFallback
                src={placeholder.image.path}
                loading="lazy"
                alt="product-category-image"
                quality={80}
                className="box-image hero-contact-image box-shadow min-width-auto min-height-auto"
                sizes="100vw"
                fill
                style={{
                  objectFit: "cover",
                }}
              />

              <h5 className="mt-0 mb-1 color-green">{placeholder.title}</h5>
              <p className="m-0 color-white line-height-1-22">
                {placeholder.subtitle}
              </p>
            </div>
          </button>
        </div>
      </CategoryList>
    </div>
  );
};

export default CategoryTeaserCards;
