import Icon from "modules/common/components/Icon";
import useCMSSystemStatusByIds from "../hooks/useCMSSystemStatusByIds";
import { useIntl } from "react-intl";
import ThemeTeaser from "modules/layout/components/ThemeTeasers";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";

export const SystemStatusCard = ({ title, tag }) => {
  const { formatMessage, formatDate } = useIntl();
  const { status: items, loading } = useCMSSystemStatusByIds();
  const systemStatusPageId = findPageIdByFilePath("/system-status");
  if (loading) return <Icon icon="loading" />;

  const { status, _modified } = (items || []).reduce(
    (acc, item) => {
      const _modified = Math.max(acc._modified, item._modified);
      if (
        item.status === "major_outage" ||
        (item.status !== "operational" && acc.status !== "major_outage")
      ) {
        return {
          status: item.status,
          _modified,
        };
      }
      return {
        ...acc,
        _modified,
      };
    },
    { status: "operational", _modified: 0 },
  );
  let updated = new Date(_modified * 1000);
  const currentDate = new Date();
  if (updated < currentDate) {
    updated = currentDate;
  }

  return (
    <ThemeTeaser pageId={systemStatusPageId} title={title} label={tag}>
      <div className="px-3 py-1 d-flex">
        <div className="mr-3">
          <div className={`bullet bullet--${status} rounded-circle`}></div>
        </div>
        <div>
          <div className="mb-2 font-weight-600">
            {status === "operational"
              ? formatMessage({
                  id: "all-operational",
                  defaultMessage: "Alle Systeme operativ",
                })
              : formatMessage({
                  id: "not-all-operational",
                  defaultMessage: "Mindestens ein System nicht voll operativ.",
                })}
          </div>
          <div>
            {formatMessage(
              {
                id: "status-last-updated",
                defaultMessage: "Zuletzt aktualisiert: {updatedDate}",
              },
              {
                updatedDate: formatDate(updated || new Date(), {
                  dateStyle: "medium",
                }),
              },
            )}
          </div>
        </div>
      </div>
    </ThemeTeaser>
  );
};

export default SystemStatusCard;
