import { gql, useQuery } from "@apollo/client";

const CurrentUserQuery = gql`
  query currentUser {
    me {
      _id
      username
      name
      roles
      profile {
        displayName
        gender
        birthday
        address {
          firstName
          lastName
          addressLine
          addressLine2
          postalCode
          city
          regionCode
        }
      }
      self {
        _id
        telephoneNumber
        type
        isInstitution
        emailAddress
        customerNumber
        contactNumber
        companyNumber
        gender
        name
        additionalName
        firstName
        lastName
        birthday
        addressLine
        addressLine2
        postalCode
        city
        countryCode
        languageCode
        insuranceName
        regionCode
        mobileNumber
        isNotifyByEmailEnabled
        isNotifyPatientByEmailEnabled
        emailNotificationAddress
        patientEmailNotificationAddress
        isNotifyByMobileEnabled
        insuranceHeadquarters
        insuranceNumber
        invalidityNumber
        familyDoctor
        isValidated
        isPrivateCustomer
        roles
        showMigelConformityInRecipes
        showMigelConformityInCatalog
        showEMSDepartment
      }
      clients {
        _id
        isInstitution
      }
    }
  }
`;

const useCurrentUser = () => {
  const { loading, error, data } = useQuery(CurrentUserQuery, {
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });

  const currentUser = data?.me;

  return {
    loading,
    error,
    currentUser,
  };
};

export const isAdministrator = (currentUser) =>
  currentUser?.self?.roles?.[0] === "ADMINISTRATOR";

export const isOrderer = (currentUser) =>
  currentUser?.self?.roles?.some((role) =>
    ["ORDERER", "ORDERER_RESTRICTED", "ORDERER_RESTRICTED_STRICT"].includes(
      role,
    ),
  );

export default useCurrentUser;
