import React from "react";
import useFormContext from "../hooks/useFormContext";

function flattenObject(ob) {
  const toReturn = {};
  for (const i in ob) {
    if (!ob[i]) continue;
    if (typeof ob[i] == "object" && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject[x]) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = { name: i, message: ob[i] };
    }
  }
  return toReturn;
}

const ErrorMessage = ({ message }) => {
  return React.isValidElement(message) ? (
    message
  ) : (
    <span dangerouslySetInnerHTML={{ __html: message || "" }} />
  );
};

const FormErrors = ({ submitError: submitErrorText = "" }) => {
  const {
    formik: { errors: fieldErrors, submitCount },
    submitError,
  } = useFormContext();

  const showErrors = submitErrorText
    ? true
    : submitCount > 0 && (submitError || Object.keys(fieldErrors).length > 0);

  const flattenedFieldErrors = flattenObject(fieldErrors);
  return showErrors ? (
    <div className="error-message ml-4">
      <ul>
        {(submitError || submitErrorText) && (
          <li className="disabled">
            <ErrorMessage message={submitError || submitErrorText} />
          </li>
        )}
        {Object.entries(flattenedFieldErrors).map(
          ([key, { name, message }]) => (
            <li key={key} className="disabled">
              <button
                type="button"
                className="no-button form-errors-item"
                aria-label="Error button"
                onClick={() =>
                  (
                    document.getElementById(key) ||
                    document.getElementById(name)
                  )?.focus()
                }
              >
                <ErrorMessage message={message} />
              </button>
            </li>
          ),
        )}
      </ul>
    </div>
  ) : null;
};

export default FormErrors;
