import localFont from "next/font/local";

const publicareFonts = localFont({
  src: [
    {
      path: "./PlutoSans-Regular-adapted-R.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "./PlutoSans-Medium-adapted-R.otf",
      weight: "600",
      style: "normal",
    },
    {
      path: "./Pluto-Regular-adapted-R.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "./Pluto-Medium-adapted-R.otf",
      weight: "600",
      style: "normal",
    },
  ],
});

export default publicareFonts;
