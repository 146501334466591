import React, { useEffect, useRef } from "react";

const CookieInformation = () => {
  const cookieBotConsentRef = useRef(null);
  useEffect(() => {
    const scriptId = "CookieDeclaration";

    if (!document.getElementById(scriptId)) {
      console.log("Creating script element...");

      const script = document.createElement("script");
      script.src =
        "https://consent.cookiebot.com/28df5a48-ffca-4698-90f7-6330b400fe19/cd.js";
      script.id = scriptId;
      script.type = "text/javascript";
      script.async = true;

      // Append the script to the document body
      console.log("Appending script to body...");
      cookieBotConsentRef.current.appendChild(script);

      return () => {
        // Remove the script when the component unmounts
        console.log("Removing script...");
        if (cookieBotConsentRef.current)
          cookieBotConsentRef.current.removeChild(script);
      };
    }
  }, []);

  return <div ref={cookieBotConsentRef} />;
};

export default CookieInformation;
