import { isValid, isEqual, isAfter } from "date-fns";

const makeFormattedPastDateFilter = (fieldName) => (item) => {
  if (!item) return false;

  const [day, month, year] = item[fieldName].split(".");
  const parsedDate = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day),
    23,
    59,
    59,
    999,
  );

  if (!isValid(parsedDate)) return false;
  return (
    isEqual(parsedDate.getTime(), new Date().getTime()) ||
    isAfter(parsedDate.getTime(), new Date().getTime())
  );
};

export default makeFormattedPastDateFilter;
