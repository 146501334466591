import thumborLoader from "modules/thumbor/loader";
import Image from "next/image";
import { useState } from "react";

const ImageWithFallback = ({ fallbackSrc = null, src, ...props }) => {
  const [imageSrc, setImageSrc] = useState(src);

  return (
    <Image
      src={imageSrc}
      blurDataURL="/static/img/no-image.png"
      loader={thumborLoader}
      placeholder="blur"
      alt={props.title || "image not found"}
      {...props}
      onError={() => {
        setImageSrc(fallbackSrc || "/static/img/no-image.png");
      }}
    />
  );
};

export default ImageWithFallback;
