import React from "react";
import ImageWithFallback from "modules/common/components/ImageWithFallback";

import Icon from "../../common/components/Icon";
import LocalizedLink from "modules/layout/components/LocalizedLink";

const CategoryListItem = ({ categoryData, className }) => {
  const cover = categoryData.media?.[0];

  return (
    <div className={className}>
      <LocalizedLink
        page={{
          pageId: "/shop",
          pathname: `${categoryData?.texts?.slug}`,
        }}
        className="d-block box-block box-shadow br-9"
      >
        <>
          <div className="overflow-hidden position-relative box-image-wrap">
            {categoryData.media?.length ? (
              <ImageWithFallback
                fill
                className="align-bottom box-image"
                src={cover?.file?.strip}
                alt={categoryData?.texts?.title || "image not found"}
              />
            ) : null}
          </div>
          <div className="d-flex justify-content-between align-items-center p-2 flex-grow-1 color-bg-blue-light br-b-9">
            <h4 className="m-1 font-weight-bold">
              {categoryData?.texts?.title}
            </h4>
            <Icon
              icon="Pfeil-rechts"
              className="icon--large"
              fill="currentColor"
            />
          </div>
        </>
      </LocalizedLink>
    </div>
  );
};

export default CategoryListItem;
