import React from "react";

interface IToastContext {
  toasts?: { type: string; meta: any }[];
  // eslint-disable-next-line no-unused-vars
  displayToast?: (toast?: { type: string; meta: any }) => void;
}

export const ToastContext = React.createContext<IToastContext>({
  toasts: [],
  displayToast: () => {},
});

const useToast = () => React.useContext(ToastContext);

export default useToast;
