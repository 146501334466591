import React from "react";
import LocalizedLink from "modules/layout/components/LocalizedLink";
import ImageWithFallback from "modules/common/components/ImageWithFallback";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";

const EventListItem = ({ className = "", event }) => {
  const academyPageId = findPageIdByFilePath("/events/:slug");
  return (
    <div className={className}>
      <LocalizedLink
        className="br-9 h-100 box-block box-shadow mb-4 d-flex flex-column color-bg-white"
        key={event._id}
        page={{ pageId: academyPageId, pathname: event.slug }}
      >
        <div className="overflow-hidden position-relative box-image-wrap-narrow">
          <ImageWithFallback
            className="box-image"
            src={event.image.path}
            alt={event.title}
            quality={80}
            layout="fill"
            sizes="100vw"
          />
        </div>
        <h4
          className="flex-grow-1 my-0 pt-3 px-3 font-weight-bold"
          dangerouslySetInnerHTML={{ __html: event.title }}
        />
        <div className="d-flex justify-content-between align-items-top px-3">
          <div className="opacity-78">{event.dateShort}</div>
        </div>
        <div className="px-3 pb-3">
          <div className="opacity-78">{event.locationShort}</div>
        </div>
        <div className="p-3 border-top">
          <div className="opacity-78">{event?.targetGroup}</div>
        </div>
      </LocalizedLink>
    </div>
  );
};

export default EventListItem;
