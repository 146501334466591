import classnames from "classnames";
import type { JSX } from "react";
import Tooltip from "../../common/components/Tooltip";
import Icon from "../../common/components/Icon";
import { ComputedProps } from "../hooks/useField";

interface FieldWrapperProps extends ComputedProps {
  children: JSX.Element;
}

const FieldWrapper = ({
  children,
  className,
  error,
  half,
  third,
  disabled,
  required,
  name,
  label,
  help,
}: FieldWrapperProps) => {
  return (
    <div
      className={classnames(className, "field-container", {
        "is-invalid": !!error,
        "field-container--half": half,
        "field-container--third": third,
        disabled,
        required,
      })}
    >
      <label
        aria-label={label}
        htmlFor={name}
        className={classnames("form-label", {
          "text-danger": !!error,
        })}
      >
        {error || label}
        {help ? (
          <Tooltip description={help} trigger={["click", "hover"]}>
            <Icon icon="information-circle" fill="currentColor" />
          </Tooltip>
        ) : (
          ""
        )}
      </label>
      {children}
    </div>
  );
};

export default FieldWrapper;
