import { useMutation, gql } from "@apollo/client";

const SEND_CONTACT_FORM = gql`
  mutation SendContactForm($formData: PublicareContactFormInput!) {
    sendContactForm(formData: $formData)
  }
`;

const useSendContactForm = (type = "CALLBACK") => {
  const [mutate, options] = useMutation(SEND_CONTACT_FORM);

  const sendContactForm = async (formData) => {
    const result = await mutate({
      variables: { formData: { ...formData, type } },
    });

    return result.data.sendContactForm;
  };

  return [sendContactForm, options];
};

export default useSendContactForm;
