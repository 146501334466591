import classNames from "classnames";

const ThTag = ({ children, className = "", ...props }) => {
  return (
    <th className={classNames("px-2 py-1", className)} {...props}>
      {children}
    </th>
  );
};

export default ThTag;
