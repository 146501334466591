import { useQuery, gql } from "@apollo/client";

const CMSPageById = gql`
  query cmsPageById($pageId: String!, $locale: String) {
    pageByID(id: $pageId, locale: $locale)
  }
`;

const useCMSPageById = ({ locale, pageId }) => {
  const { loading, error, data } = useQuery(CMSPageById, {
    variables: {
      locale,
      pageId,
    },
  });

  return {
    loading,
    error,
    page: data?.pageByID,
  };
};

export const preFetchPageById = async (apolloClient, { pageId, locale }) => {
  const { data } = await apolloClient.query({
    query: CMSPageById,
    variables: {
      locale: locale,
      pageId,
    },
  });

  return data?.pageByID;
};

export default useCMSPageById;
