import LayoutRenderer from "../LayoutRenderer";

const SectionComponent = ({ data, children }) => {
  return (
    <section className={`${data?.class || ""}`}>
      <LayoutRenderer layout={children} />
    </section>
  );
};

export default SectionComponent;
