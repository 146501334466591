import { useQuery, gql } from "@apollo/client";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import useGetToday from "../../common/hooks/useGetToday";

const selectRandomPopup = (popups) =>
  popups?.length > 0 ? popups[Math.floor(Math.random() * popups.length)] : null;

const PopupQuery = gql`
  query popup($filter: JsonType, $locale: String) {
    popupModel(filter: $filter, locale: $locale) {
      _id
      link
      image
      startDateTime
      endDateTime
      displayTo
    }
  }
`;

const usePopup = ({ isPrivate, skip }) => {
  const { locale } = useIntl();
  const { today } = useGetToday();

  const now = useMemo(() => today(), []);
  const languageFilterField = locale === "de" ? "image" : `image_${locale}`;
  const filter: {
    displayTo?: string;
    [key: string]: any;
    $and: any;
  } = {
    $and: [
      { endDateTime: { $gte: now } },
      { startDateTime: { $lte: now } },
      { [languageFilterField]: { $ne: "" } },
    ],
  };

  const { data, error } = useQuery(PopupQuery, {
    variables: {
      filter,
      locale,
    },
    skip,
    notifyOnNetworkStatusChange: true,
  });

  const shouldDisplayTo =
    isPrivate === undefined || isPrivate === null
      ? "anonymous-home"
      : isPrivate
        ? "private"
        : "institution";

  const filteredPopups = (data?.popupModel || []).filter((popup) =>
    popup.displayTo.includes(shouldDisplayTo),
  );
  const popup = selectRandomPopup(filteredPopups);

  return {
    popup,
    error,
  };
};

export default usePopup;
