import { gql, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

export const EVENTS_CATEGORY_QUERY = gql`
  query cmsGetEventCategories($locale: String) {
    eventcatagoriesModel(locale: $locale) {
      _id
      catagory
      index
      slug
      contentTitle
      navigationTitle
    }
  }
`;

export const sortCategories = (eventCategories = []) => {
  return eventCategories.slice().sort((a, b) => a.index - b.index);
};

const useEventsCategories = ({ forceLocale = "" } = {}) => {
  const intl = useIntl();
  const lang = intl.locale;

  const {
    data: { eventcatagoriesModel = [] } = {},
    error,
    loading,
  } = useQuery(EVENTS_CATEGORY_QUERY, {
    variables: {
      locale: forceLocale || lang,
    },
  });

  return {
    loading,
    error,
    categories: sortCategories(eventcatagoriesModel).map(
      ({ catagory, ...c }) => ({ ...c, category: catagory }),
    ),
  };
};

export const preFetchEventCategories = async (apolloClient, { locale }) => {
  const { data: { eventcatagoriesModel = [] } = {} } = await apolloClient.query(
    {
      query: EVENTS_CATEGORY_QUERY,
      variables: { locale },
    },
  );
  return eventcatagoriesModel;
};

export default useEventsCategories;
