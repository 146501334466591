import { useIntl } from "react-intl";

const VideoComponent = ({ data }) => {
  const { formatMessage } = useIntl();

  const { poster, video } = data;
  if (!video) return null;
  return (
    <div className="position-relative embed-container my-5">
      <video className="w-100" poster={poster?.path} controls autoPlay={false}>
        <source src={video.path} type={video.mime} />
        {formatMessage({
          id: "video-player-not-supported",
          defaultMessage: "Ihr Browser unterstützt den Videoplayer nicht.",
        })}
      </video>
    </div>
  );
};

export default VideoComponent;
