import classNames from "classnames";
import Obfuscate from "../../../common/components/Obfuscate";
import LocalizedLink from "../LocalizedLink";

const telRegex = /tel:(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/g;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const mailtoRegex = /^mailto:/;

const AnchorTag = ({ href, className, children, id, ...props }) => {
  if (id && !href)
    return (
      <a
        href={`#${id}`}
        id={id}
        className={classNames("content-anchor-link", className)}
        {...props}
      >
        {children}
      </a>
    );
  if (!href) return null;
  const isEmail = emailRegex.test(href) || mailtoRegex.test(href);
  const isPhone = telRegex.test(href);
  const shouldBeObfuscated = isPhone || isEmail;
  if (shouldBeObfuscated)
    return (
      <Obfuscate
        email={isEmail ? href : null}
        tel={isPhone ? href : null}
        className={classNames("link d-inline-block", className)}
        {...props}
      >
        {children}
      </Obfuscate>
    );

  return (
    <LocalizedLink
      className={classNames("link", className)}
      page={{ pageId: href }}
      {...props}
    >
      {children}
    </LocalizedLink>
  );
};

export default AnchorTag;
