import { gql, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

const RELEASES_QUERY = gql`
  query Releases($locale: String, $filter: JsonType) {
    releasesModel(locale: $locale, filter: $filter, sort: { version: -1 }) {
      _id
      version
      date
      summary
      content
    }
  }
`;

const RELEASE_QUERY = gql`
  query Release($locale: String, $filter: JsonType) {
    releasesModel(locale: $locale, filter: $filter, limit: 1) {
      _id
      version
      date
      summary
      content
    }
  }
`;

const useReleases = () => {
  const intl = useIntl();
  const locale = intl.locale;

  const { data, error, loading } = useQuery(RELEASES_QUERY, {
    variables: {
      locale,
    },
  });

  return {
    loading,
    error,
    releases: data?.releasesModel || [],
  };
};

export const preFetchReleases = async (apolloClient, { locale }) => {
  const filter: any = {
    language: locale,
  };

  const { data } = await apolloClient.query({
    query: RELEASES_QUERY,
    variables: {
      filter,
    },
  });

  return data?.releasesModel || [];
};

export const preFetchRelease = async (apolloClient, { locale, version }) => {
  const filter: any = {
    language: locale,
    version,
  };

  const { data } = await apolloClient.query({
    query: RELEASE_QUERY,
    variables: {
      filter,
    },
  });

  return (data?.releasesModel || [])?.pop();
};

export default useReleases;
