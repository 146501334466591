import LocalizedLink from "./LocalizedLink";

const AssetLink = ({ documentLink, label }) => {
  if (!documentLink?.path || !label) return null;

  return (
    <div className="border-bottom py-3">
      <a
        href={documentLink.path}
        target="_blank"
        rel="noopener noreferrer"
        className="d-flex justify-content-between my-1 link-hover"
      >
        <span>{label}</span>
        <span className="icon icon--office-file-pdf-1 ml-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M8.451 12.322c-.105-.046-.326-.048-.326.164v3.978a.238.238 0 0 0 .327.21 2.375 2.375 0 0 0 0-4.352ZM4 12.123h-.623a.252.252 0 0 0-.252.252v1.246a.252.252 0 0 0 .252.252H4a.875.875 0 0 0 0-1.75Z"></path>
            <path d="M18 19v-9a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1ZM4 15.123h-.623a.252.252 0 0 0-.252.252V17.5a.625.625 0 0 1-1.25 0v-6a.625.625 0 0 1 .625-.625H4a2.125 2.125 0 0 1 0 4.25Zm3.5 3a.625.625 0 0 1-.625-.625v-6a.625.625 0 0 1 .625-.625 3.625 3.625 0 0 1 0 7.25Zm8-6h-2a.375.375 0 0 0-.375.375v1.123a.252.252 0 0 0 .252.252H14.5a.625.625 0 0 1 0 1.25h-1.123a.252.252 0 0 0-.252.252V17.5a.625.625 0 0 1-1.25 0v-5a1.627 1.627 0 0 1 1.625-1.627h2a.625.625 0 0 1 0 1.25Z"></path>
            <path d="M23.707 5.705 18.293.291A1 1 0 0 0 17.585 0H6a2 2 0 0 0-2 2v5.75a.25.25 0 0 0 .25.25h1.5A.25.25 0 0 0 6 7.748V2.5a.5.5 0 0 1 .5-.5h10.25a.25.25 0 0 1 .25.25V5a2 2 0 0 0 2 2h2.75a.25.25 0 0 1 .25.25V21.5a.5.5 0 0 1-.5.5H6.25a.25.25 0 0 1-.25-.25v-.5a.248.248 0 0 0-.249-.25H4.25a.25.25 0 0 0-.25.25V22a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6.412a1 1 0 0 0-.293-.707Z"></path>
          </svg>
        </span>
      </a>
    </div>
  );
};

export default AssetLink;
