import parseDate from "../../forms/utils/parseDate";

const makeFormattedDateSorter = (fieldName, descending = false) => {
  return (left, right) => {
    const parsedLeft = parseDate(left[fieldName]);
    const parsedRight = parseDate(right[fieldName]);
    if (descending) return parsedRight.getTime() - parsedLeft.getTime();
    return parsedLeft.getTime() - parsedRight.getTime();
  };
};

export default makeFormattedDateSorter;
