const AlertText = ({ text }) => {
  if (!text) return null;
  return (
    <div className="h4 color-bg-blue-light p-3 border-top border-bottom">
      {text}
    </div>
  );
};

export default AlertText;
