import DOMRenderer from "modules/layout/components/DOMRenderer";

const HTMLComponent = ({ data, showTableOfContent = false }) => {
  if (!data || !data?.html) return null;
  return (
    <DOMRenderer
      htmlString={data.html}
      showTableOfContent={showTableOfContent}
    />
  );
};

export default HTMLComponent;
