import { gql, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

const GET_MAINTENANCE = gql`
  query cmsGetCMSMaintenance($lang: String) {
    maintenanceModel(locale: $lang, sort: { _o: 1 }) {
      _id
      from
      to
      text
    }
  }
`;

const useCMSMaintenance = () => {
  const intl = useIntl();
  const lang = intl.locale.split("-")[0];

  const { data, error, loading } = useQuery(GET_MAINTENANCE, {
    variables: {
      lang,
    },
  });

  return {
    loading,
    error,
    maintenance: data?.maintenanceModel || [],
  };
};

export default useCMSMaintenance;
