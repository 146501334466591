import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import Icon from "./Icon";

const Tooltip = ({
  children,
  tooltip = undefined,
  hideArrow = false,
  title = "",
  description = null,
  fullwidth = false,
  showIconInTooltipMessage = false,
  ...props
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    placement,
  } = usePopperTooltip({
    ...props,
    offset: [0, 7],
    interactive: true,
    trigger: ["click", "hover"],
  });

  return (
    <div
      className={`trigger ${fullwidth ? "full-width" : ""}`}
      ref={setTriggerRef}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <span>
        {children || <Icon icon="information-circle" fill="currentColor" />}
      </span>

      {visible && (
        <div
          ref={setTooltipRef}
          className={`tooltip-container color-bg-white p-3 ${props.type || ""}`}
          {...getTooltipProps()}
        >
          {!hideArrow && (
            <div
              className="tooltip-arrow"
              data-placement={placement || "auto"}
              {...getArrowProps()}
            />
          )}
          {props.type === "error" && (
            <div className="tooltip-icon">
              <Icon icon="road-sign-warning" fill="currentColor" />
            </div>
          )}
          <div>
            {!!title && <b className="mb-2 d-block">{title}</b>}
            <div className="d-flex w-100">
              {showIconInTooltipMessage && (
                <span className="mr-2">
                  {children || (
                    <Icon icon="information-circle" fill="currentColor" />
                  )}
                </span>
              )}
              <span>{tooltip || description}</span>
            </div>
          </div>
          <button className="no-button top-right hide-on-not-mobile mt-2 mr-2">
            <Icon icon="close" className="icon--smaller" fill="currentColor" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
