import classNames from "classnames";

const TrTag = ({ children, className = "", ...props }) => {
  return (
    <tr className={classNames("table-row", className)} {...props}>
      {children}
    </tr>
  );
};

export default TrTag;
