import React from "react";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import useCMSSitemap, { preFetchSiteMap } from "../hooks/useCMSSitemap";
import type { UrlObject } from "url";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";

export type LocalizedUrlObject = UrlObject & {
  pageId: string;
};

const parsePageId = (pageId: string): string => {
  try {
    const [, pageUrl] = (pageId || "").split("pages://");
    const [normalizedPageUrl] = (pageUrl || "").split("?");
    return normalizedPageUrl || pageId;
  } catch (e) {
    console.error(e);
    return pageId;
  }
};

const buildHref = (
  sitemap: any[],
  { pageId, ...page }: LocalizedUrlObject,
  locale: string,
): UrlObject | string => {
  if (!pageId) {
    return "";
  }

  const parsedPageId = parsePageId(pageId);
  const foundPage = sitemap.find(
    (p) => p._id === parsedPageId || p._r === pageId,
  );

  if (foundPage) {
    const homePageId = findPageIdByFilePath("/home");
    const normalizedHome = foundPage._id === homePageId ? "/" : foundPage._r;
    const href = {
      ...page,
      pathname: `/${locale}${
        normalizedHome.startsWith("/") ? "" : "/"
      }${normalizedHome}/${page.pathname || ""}`,
    };
    return href;
  }
  //if error thrown handle  path as relative relative
  try {
    return new URL(pageId);
  } catch (error) {
    const href = ` /${locale}${pageId.startsWith("/") ? "" : "/"}${pageId}`;
    return href;
  }
};

export const getLocalizedRoute = async (
  apollo: any,
  urlObject: LocalizedUrlObject,
  locale: string,
): Promise<{ route: string; locale: string }> => {
  const siteMap = await preFetchSiteMap(apollo, { locale });
  const href = buildHref(siteMap, urlObject, locale);

  let path: string;

  if (typeof href === "object") {
    path = href.pathname || href.href || "";
  } else {
    path = href;
  }

  const [, ...normalizedRoute] = path.split("/").filter(Boolean);

  return {
    route: `/${normalizedRoute.join("/")}`,
    locale,
  };
};

export const useLocalizedRoute = (
  urlObject: LocalizedUrlObject,
  locale: string,
): { href: UrlObject | string; locale: string } => {
  const { locale: siteLocale } = useIntl();
  const normalizedLocale = locale || siteLocale;
  const sitemap = useCMSSitemap(normalizedLocale);
  const href = buildHref(sitemap, urlObject, normalizedLocale);
  return { href, locale };
};

export const useLazyLocalizedRoute = ({ forceLocale = null } = {}) => {
  const { locale } = useIntl();
  const normalizedLocale = forceLocale || locale;

  const sitemap = useCMSSitemap(normalizedLocale);
  const getCMSPage = (routePath) => {
    const parsedPageId = parsePageId(routePath);
    const foundPage = sitemap.find(
      (p) => p._id === parsedPageId || p._r === routePath,
    );

    if (foundPage) {
      return foundPage;
    }
    return routePath;
  };
  return { getCMSPage };
};

export type LocalizedLinkProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof LinkProps | "href"
> &
  Omit<LinkProps, "href"> & {
    children?: React.ReactNode;
    page: LocalizedUrlObject;
  } & React.RefAttributes<HTMLAnchorElement>;

const LocalizedLink = ({ page, ...props }: LocalizedLinkProps) => {
  const router = useRouter();
  const { href } = useLocalizedRoute(page, props.locale || router.locale);
  if (typeof href === "string") {
    const { pageId, ...restPageProp } = page;
    return (
      <Link
        prefetch={false}
        {...props}
        href={{ ...restPageProp, pathname: href }}
      />
    );
  }
  return <Link prefetch={false} {...props} href={href} />;
};

export default LocalizedLink;
