import React from "react";
import useCMSEmployee from "../hooks/useCMSEmployee";
import Loading from "modules/layout/components/Loading";

const ConsultingTeaser = ({ label, consultant }) => {
  const { employee, loading } = useCMSEmployee({ employeeId: consultant._id });
  return (
    <div className="mt-5 py-2 contact-box" id="contact-box">
      {loading && <Loading />}
      {employee && (
        <div className="container">
          <div className="text-center">
            <>
              <h3 className="color-blue">{label}</h3>
              <img
                src={employee.image?.path}
                alt={employee.image?.title}
                className="hero-contact-image mt-1 mb-0"
                onError={(event) => {
                  const element = event.target;
                  if (element instanceof HTMLImageElement) {
                    element.src = "/static/img/avatar.png";
                    element.onerror = null;
                  }
                }}
              />
              <div>
                <b>{employee.name}</b>
              </div>
              <div className="mb-4">{employee.title}</div>
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsultingTeaser;
