import { useQuery, gql } from "@apollo/client";

const CMSRouteParentAndSiblingsQuery = gql`
  query CmsRouteSiblings($route: String!, $locale: String) {
    cmsRouteSiblings(route: $route, locale: $locale)
  }
`;

const useCMSRouteParentAndSiblings = ({
  route,
  locale,
}: {
  locale: string;
  route: string;
}) => {
  const { loading, error, data } = useQuery(CMSRouteParentAndSiblingsQuery, {
    variables: {
      locale,
      route: route,
    },
    skip: !route,
  });

  return {
    loading,
    error,
    siblings: data?.cmsRouteSiblings || [],
  };
};

export default useCMSRouteParentAndSiblings;
