import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import rehypeSanitize from "rehype-sanitize";

const MarkdownComponent = ({ data, label }) => {
  if (!data || !data?.markdown) return null;

  return (
    <ReactMarkdown
      // eslint-disable-next-line react/no-children-prop
      children={data.markdown}
      rehypePlugins={[rehypeRaw, remarkGfm, rehypeSanitize]}
    />
  );
};

export default MarkdownComponent;
