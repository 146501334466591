import { createContext } from "react";
import ButtonComponent from "./blocks/ButtonComponent";
import DividerComponent from "./blocks/DividerComponent";
import GridComponent from "./blocks/GridComponent";
import HeadingComponent from "./blocks/HeadingComponent";
import HTMLComponent from "./blocks/HTMLComponent";
import ImageComponent from "./blocks/ImageComponent";
import LinkComponent from "./blocks/LinkComponent";
import MarkdownComponent from "./blocks/MarkdownComponent";
import RichTextComponent from "./blocks/RichTextComponent";
import SectionComponent from "./blocks/SectionComponent";
import SpacerComponent from "./blocks/SpacerComponent";
import VideoComponent from "./blocks/VideoComponent";

const layoutComponentMap = {
  divider: DividerComponent,
  button: ButtonComponent,
  grid: GridComponent,
  heading: HeadingComponent,
  image: ImageComponent,
  link: LinkComponent,
  richtext: RichTextComponent,
  html: HTMLComponent,
  section: SectionComponent,
  spacer: SpacerComponent,
  video: VideoComponent,
  markdown: MarkdownComponent,
};

export const CockpitContext = createContext(layoutComponentMap);

const CockpitContextProvider = ({ children, customComponents }) => {
  return (
    <CockpitContext.Provider
      value={{ ...layoutComponentMap, ...customComponents }}
    >
      {children}
    </CockpitContext.Provider>
  );
};

export default CockpitContextProvider;
