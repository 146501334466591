import CategoryTeaserCards from "../categories/components/CategoryTeaserCards";
import Advantages from "./components/Advantages";
import Hero from "./components/Hero";
import EventTeaserCards from "../events/components/EventTeaserCards";
import StoryTeaserCards from "../stories/components/StoryTeaserCards";
import CallToActionTeaser from "./components/CallToActionTeaser";
import ConsultingTeaser from "../team/components/ConsultingTeaser";
import PageTeaserCard from "./components/PageTeaserCard";
import SiblingPageTeasers from "./components/SiblingPageTeasers";
import AppointmentCallToAction from "./components/AppointmentCallToAction";
import GoToButton from "./components/GoToButton";
import AssetLink from "./components/AssetLink";
import TeamFilters from "modules/team/components/TeamFilter";
import TeamList from "modules/team/components/TeamCollection";
import PageContentTeaser from "./components/PageContentTeaser";
import HorizontalDefinitionListItem from "./components/HorizontalDefinitionListItem";
import FAQList from "modules/help/components/FAQList";
import MissionCard from "./components/MissionCard";
import DocumentListItem from "./components/DocumentListItem";
import DownloadableLinkItem from "./components/DownloadableLinkItem";
import CookieInformation from "./components/CookieInformation";
// import NewsletterSubscribeForm from "modules/newsletter/components/NewsletterSubscribeForm";
import PublicareMapIFrame from "./components/PublicareMapIFrame";
import AlertText from "./components/AlertText";
import ExtendedButtonComponent from "./components/ExtendedButtonComponent";
import useCMSFAQs from "modules/help/hooks/useCMSFAQs";
import Loading from "./components/Loading";
import InternalDownloadable from "modules/downloads/components/InternalDownloadable";
import SystemStatusSection from "../system-status/components/SystemStatusSection";
import MaintenanceSection from "../system-status/components/MaintenanceSection";
import SystemStatusBanner from "modules/system-status/components/SystemStatusBanner";
import SystemStatusCard from "modules/system-status/components/SystemStatusCard";
import ReleasesCard from "modules/releases/components/ReleasesCard";
import Script from "next/script";
import { useIntl } from "react-intl";

const CockpitLayoutComponents = {
  Hero: ({ data }) => <Hero image={data.image} content={data.content} />,
  AdvantageTeaserCards: ({ data }) => (
    <Advantages advantages={data.advantages} title={data?.title} />
  ),
  HomeCategories: ({ data }) => (
    <CategoryTeaserCards
      title={data?.title}
      placeholder={{
        title: data.placeholder_title,
        subtitle: data.placeholder_subtitle,
        image: data.placeholder_image,
      }}
    />
  ),
  StoryTeaserCards: ({ data }) => (
    <StoryTeaserCards title={data.title} subtitle={data.subtitle} />
  ),
  AcademyTeaserCards: ({ data }) => (
    <EventTeaserCards title={data?.title} subtitle={data.subtitle} />
  ),
  CallToActionTeaser: ({ data }) => {
    if (!data?.link) return null;

    return (
      <CallToActionTeaser
        title={data?.title}
        subtitle={data.subtitle}
        link={data.link}
        linkText={data?.linkText}
        forceLocale={data.link?.locale}
      />
    );
  },
  AppointmentCallToAction: ({ data }) => (
    <AppointmentCallToAction title={data?.title} subtitle={data.subtitle} />
  ),
  ConsultingTeaser: ({ data }) => (
    <ConsultingTeaser label={data?.title} consultant={data.consultant} />
  ),
  PageTeaserCard: ({ data }) => {
    if (!data?.page || typeof data.page !== "string") return null;
    return <PageTeaserCard uri={data.page} />;
  },
  SiblingPageTeasers: ({ data }) => {
    if (!data?.parentPage) return null;
    const normalizedCmsLocale =
      data.parentPage?.locale === "default" ? "de" : data.parentPage?.locale;
    return (
      <SiblingPageTeasers
        parentPage={data?.parentPage}
        forceLocale={normalizedCmsLocale}
      />
    );
  },
  GoToButton: ({ data }) => {
    return (
      <GoToButton
        targetPage={data?.targetPage}
        label={data?.title}
        align={data.alignButton}
      />
    );
  },
  AssetLink: ({ data }) => {
    return <AssetLink documentLink={data?.documentLink} label={data?.title} />;
  },
  TeamFilter: () => {
    return <TeamFilters />;
  },
  TeamList: ({ data }) => {
    return <TeamList header={data?.header} />;
  },
  PageContentTeaser: ({ data }) => {
    if (!data.contentId) return null;
    const normalizedCmsLocale =
      data?.contentId?.locale === "default" ? "de" : data?.contentId?.locale;
    return (
      <PageContentTeaser
        pageId={data.contentId}
        limit={data?.limit}
        header={data?.header}
        forceLocale={normalizedCmsLocale}
      />
    );
  },
  HorizontalDefinitionListItem: ({ data }) => {
    if (!data?.definition) return null;
    return (
      <HorizontalDefinitionListItem
        definition={data?.definition}
        label={data?.title}
      />
    );
  },
  FaqList: ({ locale = "" } = {}) => {
    const normalizedLocale = locale === "default" ? "de" : locale;
    const { faqs, loading } = useCMSFAQs({ forceLocale: normalizedLocale });
    if (loading) return <Loading />;
    return <FAQList faqs={faqs} />;
  },
  MissionCard: ({ data }) => {
    return <MissionCard values={data?.values} />;
  },
  DocumentItem: ({ data }) => {
    if (!data?.document?.path) return null;
    return (
      <DocumentListItem
        date={data?.date}
        document={data?.document}
        title={data?.title}
      />
    );
  },
  DownloadableItem: ({ data }) => {
    if (!data?.document?.path) return null;
    return (
      <DownloadableLinkItem document={data?.document} title={data?.title} />
    );
  },
  Cookie: () => {
    return <CookieInformation />;
  },

  NewsletterSubscriptionForm: () => {
    const intl = useIntl();
    const lang = intl.locale.split("-")[0];
    return (
      <>
        <div id="formContentId" />
        <Script
          id="mailxpert-form"
          strategy="lazyOnload"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
          mailxpert.forms.include({
            src: 'https://nl.mailxpert.ch/e/f72b183b3bfcc8c9/${lang}/form/73ebcc55-035b-4976-879b-8de12e762446.html?render=container',
            element: document.getElementById('formContentId'),
        });
      `,
          }}
        />
      </>
    );
    // return <NewsletterSubscribeForm />;
  },
  Map: () => {
    return <PublicareMapIFrame />;
  },
  AlertText: ({ data }) => {
    return <AlertText text={data?.title} />;
  },
  ExtendedButton: ({ data }) => {
    if (!data?.targetPage) return null;
    return (
      <ExtendedButtonComponent
        targetPage={data.targetPage}
        label={data?.title}
        forceLocale={data?.targetPage?.locale}
      />
    );
  },
  HRDownloadableForm: ({ data }) => (
    <InternalDownloadable title={data?.title} subtitle={data?.subtitle} />
  ),
  SystemStatusSection: ({ data }) => (
    <SystemStatusSection
      title={data?.label}
      systemStatusIds={data?.statusItems?.map((s) => s._id)}
    />
  ),
  MaintenanceSection: ({ data }) => <MaintenanceSection title={data?.label} />,
  SystemStatusBanner: () => <SystemStatusBanner />,
  SystemStatusCard: ({ data }) => (
    <SystemStatusCard title={data?.title} tag={data?.tag} />
  ),
  ReleasesCard: ({ data }) => (
    <ReleasesCard title={data?.title} tag={data?.tag} />
  ),
};

export default CockpitLayoutComponents;
