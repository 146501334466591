import classnames from "classnames";

import { validateMaxLength } from "../utils/validators";
import useField, { FieldHookProps } from "../hooks/useField";
import FieldWrapper from "./FieldWrapper";

export interface TextFieldProps extends FieldHookProps {
  autoComplete?: "on" | "off";
  type?: "text" | "email" | "password" | "number";
  min?: number;
  max?: number;
  maxLength?: number;
  onPaste?: any;
}

const TextField = ({
  maxLength,
  validators = [],
  ...props
}: TextFieldProps) => {
  const field = useField({
    validators: [...validators, maxLength && validateMaxLength(maxLength)],
    ...props,
  });

  return (
    <FieldWrapper {...field}>
      <input
        className={classnames("form-field", {
          "form-field--error": !!field.error,
        })}
        disabled={field.disabled}
        id={field.name}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        placeholder={field.placeholder}
        autoComplete={field.autoComplete}
        type={field.type}
        value={field.value}
        onPaste={props?.onPaste}
        min={props?.min}
        max={props?.max}
      />
    </FieldWrapper>
  );
};

export default TextField;
