/* eslint-disable no-unused-vars, no-undef*/
import React from "react";

export type SetModal = (
  children: JSX.Element | string,
  options?: { disableOkButton: boolean; closeOnOutsideClick?: boolean },
) => Promise<boolean>;

const ModalContext = React.createContext<{ setModal: SetModal }>({
  setModal: () => {
    throw new Error("No ModalContext/ModalWrapper ancestor found.");
  },
});

export default ModalContext;
