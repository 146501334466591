import classNames from "classnames";

const ImageTag = ({
  src,
  className = "",
  alt = "not found",
  children,
  ...props
}) => {
  return (
    <img
      src={src}
      className={classNames("link", className)}
      onError={(event) => {
        const element = event?.target;
        if (element instanceof HTMLImageElement) {
          element.src = "/static/img/no-image.png";
          element.onerror = null;
        }
      }}
      alt={alt}
      {...props}
    />
  );
};

export default ImageTag;
