import StoryList from "./StoryList";
import { useIntl } from "react-intl";
import LocalizedLink from "../../layout/components/LocalizedLink";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";

const StoryTeaserCards = ({ title, subtitle }) => {
  const intl = useIntl();
  const storiesPageId = findPageIdByFilePath("/stories/:slug");

  return (
    <div className="color-bg-green-lightest mt-5 pt-3 pb-5 full-width">
      <div className="container">
        <div className="d-flex flex-wrap justify-content-between align-items-baseline">
          <h3 className="mr-2">{title}</h3>
          <LocalizedLink
            page={{ pageId: storiesPageId }}
            className="button button--secondary mb-4"
          >
            {intl.formatMessage({
              id: "stories_link_to_all",
              defaultMessage: "Alle Stories",
            })}
          </LocalizedLink>
          {subtitle && (
            <h4 className="mt-0 mb-3 w-75 w-100-for-mobile">{subtitle}</h4>
          )}
        </div>
        <StoryList type="homepage" limit={3} disableLoadMore />
      </div>
    </div>
  );
};

export default StoryTeaserCards;
