import { useIntl } from "react-intl";
import LocalizedLink from "./LocalizedLink";

const ExtendedButtonComponent = ({ targetPage, label, forceLocale = null }) => {
  const intl = useIntl();
  const normalizedLocale = forceLocale || intl.locale;
  let normalizedLink = null;
  if (typeof targetPage === "string") normalizedLink = targetPage;
  else if (typeof targetPage === "object" && targetPage?.route)
    normalizedLink = targetPage.route;

  return (
    <div className="text-center my-5">
      <LocalizedLink
        page={{ pageId: normalizedLink }}
        locale={normalizedLocale}
        className={`button button--primary `}
      >
        {label}
      </LocalizedLink>
    </div>
  );
};

export default ExtendedButtonComponent;
