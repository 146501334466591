import React from "react";
import { useIntl } from "react-intl";
import ThemeTeaser from "./ThemeTeasers";
import useCMSRouteParentAndSiblings from "modules/navigation/hooks/useCMSRouteParentAndSiblings";
import { useLazyLocalizedRoute } from "./LocalizedLink";
import Loading from "./Loading";

const SiblingPageTeasers = ({ parentPage, forceLocale = null }) => {
  const intl = useIntl();
  const { getCMSPage } = useLazyLocalizedRoute({ forceLocale });
  let normalizedRoute = null;
  const normalizedLocale = forceLocale || intl.locale;

  if (typeof parentPage === "string") {
    normalizedRoute = parentPage;
  } else if (typeof parentPage === "object" && parentPage?.route) {
    normalizedRoute = parentPage.route;
  }
  const pageInfo = getCMSPage(normalizedRoute);
  const { siblings, loading } = useCMSRouteParentAndSiblings({
    route: pageInfo?._r,
    locale: normalizedLocale,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="row mt-5">
      {siblings
        .filter(({ _state, seo }) => _state && seo?.title)
        .map(({ _id, seo, title }) => (
          <div className="col-md-6 mb-4" key={_id}>
            <ThemeTeaser {...seo} pageId={_id} label={title} title={title}>
              <p
                className="px-3 py-1"
                dangerouslySetInnerHTML={{ __html: seo.description }}
              />
            </ThemeTeaser>
          </div>
        ))}
    </div>
  );
};

export default SiblingPageTeasers;
