export default function thumborLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  if (src)
    return `/api/thumb?src=${encodeURIComponent(
      src,
    )}&width=${width}&quality=${quality}`;

  return "/static/img/no-image.png";
}
