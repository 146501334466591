import DOMRenderer from "./DOMRenderer";

const MissionCard = ({ values }) => {
  const {
    sectionHeaderTitle,
    sectionHeaderContent,
    firstItemHeader,
    firstItemContent,
    secondItemHeader,
    secondItemContent,
    thirdItemHeader,
    thirdItemContent,
  } = values;
  return (
    <>
      <div className="position-relative mt-5 pt-2">
        <span className="circles-before-title">&nbsp;</span>
        <h3 className="ml-5 mx-0 px-0 pl-4">{sectionHeaderTitle}</h3>
      </div>
      <DOMRenderer htmlString={sectionHeaderContent} className="pl-md-5" />

      <div className="row">
        <div className="col-md-6 col-lg-4">
          <h5 className="title-with-circle-1 pl-4">{firstItemHeader}</h5>
          <DOMRenderer htmlString={firstItemContent} />
        </div>
        <div className="col-md-6 col-lg-4">
          <h5 className="title-with-circle-2 pl-4">{secondItemHeader}</h5>
          <DOMRenderer htmlString={secondItemContent} />
        </div>
        <div className="col-md-6 col-lg-4">
          <h5 className="title-with-circle-3 pl-4">{thirdItemHeader}</h5>
          <DOMRenderer htmlString={thirdItemContent} />
        </div>
      </div>
    </>
  );
};

export default MissionCard;
