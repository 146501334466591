import DOMRenderer from "modules/layout/components/DOMRenderer";
import { useIntl } from "react-intl";

export const MaintenanceItem = ({ from, to, text }) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const intl = useIntl();

  let dateString = "n/a";
  if (fromDate.getDate() === toDate.getDate()) {
    // same date!
    dateString = `${intl.formatDate(from, {
      dateStyle: "short",
    })}, ${intl.formatDate(from, {
      timeStyle: "short",
    })} - ${intl.formatDate(to, { timeStyle: "short" })}`;
  } else {
    dateString = `${intl.formatDate(from, {
      dateStyle: "short",
      timeStyle: "short",
    })} - ${intl.formatDate(to, {
      dateStyle: "short",
      timeStyle: "short",
    })}`;
  }

  return (
    <>
      <b>{dateString}</b>
      <DOMRenderer className="font-size-1" htmlString={text} />
    </>
  );
};

export default MaintenanceItem;
