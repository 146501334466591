import DOMRenderer from "./DOMRenderer";

const HorizontalDefinitionListItem = ({ label, definition }) => {
  return (
    <div className="border-horizontal py-3">
      <div className="row">
        <div className="col-md-5 mb-2 mb-md-0">
          <b>{label}</b>
        </div>
        <div className="col-md-7">
          <DOMRenderer htmlString={definition} />
        </div>
      </div>
    </div>
  );
};
export default HorizontalDefinitionListItem;
