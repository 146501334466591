import Icon from "../../common/components/Icon";
import useCMSAdvantagesQuery from "../../layout/hooks/useCMSAdvantages";

const Advantage = ({ advantage }) => {
  return advantage ? (
    <div className="col-lg-4 flex-column d-flex flex-md-grow-1 h-100 advantage-card">
      <div className="flex-md-grow-1 d-flex my-auto w-100">
        <div className="d-flex align-items-center">
          <Icon icon="Positiv" fill="currentColor" className="icon--l mr-1" />
          <h4 className="my-0 ml-2">
            <b>{advantage.title}</b>
          </h4>
        </div>
      </div>
      <div
        className="flex-md-grow-1 d-flex mb-3 mt-0 mt-md-2"
        style={{ marginLeft: "3.75rem" }}
      >
        {advantage.text}
      </div>
    </div>
  ) : (
    ""
  );
};

const Advantages = ({ advantages: links, title }) => {
  const { advantages } = useCMSAdvantagesQuery();
  const advantagesWithData = links
    .flatMap((link) =>
      advantages.find((advantage) => advantage._id === link._id),
    )
    .filter(Boolean);
  return (
    <>
      <h3 className="pt-4">{title}</h3>
      <div className="row mb-5">
        {advantagesWithData.map((advantage) => (
          <Advantage key={advantage._id} advantage={advantage} />
        ))}
      </div>
    </>
  );
};

export default Advantages;
