import Link from "next/link";

const LinkComponent = ({ data }) => {
  const href = data?.url || "";

  return (
    <Link
      className={`paragraph d-block my-3 link ${data?.class || ""}`}
      aria-label={data?.caption}
      href={href}
      prefetch={false}
      target={data?.target}
    >
      {data?.caption}
    </Link>
  );
};

export default LinkComponent;
