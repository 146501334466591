import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";
import LocalizedLink from "modules/layout/components/LocalizedLink";
import React from "react";
import { useIntl } from "react-intl";
import EventList from "./EventList";

const EventTeaserCards = ({ title, subtitle }) => {
  const intl = useIntl();
  const academyPageId = findPageIdByFilePath("/events/:slug");
  return (
    <div className="mt-5">
      <div className="d-flex flex-wrap justify-content-between align-items-baseline mb-3">
        <h3 className="mr-2 mb-2 mt-2">{title}</h3>
        <LocalizedLink
          page={{ pageId: academyPageId }}
          className="button button--secondary mb-3 d-block"
        >
          {intl.formatMessage({
            id: "learn_more",
            defaultMessage: "Mehr erfahren",
          })}
        </LocalizedLink>
        {subtitle && (
          <h3 className="font-weight-light mt-0 mb-3 w-75 w-100-for-mobile">
            {subtitle}
          </h3>
        )}
      </div>

      <EventList type="homepage" limit={3} disableLoadMore />
    </div>
  );
};

export default EventTeaserCards;
