import { isBefore, isEqual, isValid } from "date-fns";
import parseDate from "../../forms/utils/parseDate";

const makeFormattedFutureDateFilter =
  (fieldName, format = "d.M.y") =>
  (item) => {
    const parsedPublicationDate = parseDate(item[fieldName], format);
    if (!isValid(parsedPublicationDate)) return false;
    const today = new Date().getTime();
    return (
      isEqual(parsedPublicationDate.getTime(), today) ||
      isBefore(parsedPublicationDate.getTime(), today)
    );
  };

export default makeFormattedFutureDateFilter;
