import React from "react";
import TeamCollectionItem from "./TeamCollectionItem";
import useCMSTeam from "../hooks/useCMSTeam";
import Loading from "modules/layout/components/Loading";
import { useRouter } from "next/router";

const TeamList = ({ header }) => {
  const router = useRouter();
  const { department, competence } = router?.query || {};
  const { team, loading } = useCMSTeam({
    competence,
    department,
  });

  return (
    <>
      <h1>{header}</h1>
      {team?.length > 0 && (
        <div className="text-center text-break">
          <div className="row">
            {team.map((employee) => (
              <TeamCollectionItem key={employee._id} employee={employee} />
            ))}
          </div>
        </div>
      )}
      {loading ? <Loading /> : ""}
    </>
  );
};

export default TeamList;
