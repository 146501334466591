import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import CompetenceDropdown from "./CompetenceDropdown";
import DepartmentDropdown from "./DepartmentDropdown";

const TeamFilters = () => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const updateRoute = (key, value) => {
    let updatedQuery;
    const query = router.query;
    if (!value) {
      if (query[key]) delete query[key];
      updatedQuery = query;
    } else {
      updatedQuery = { ...query, [key]: value };
    }

    router.push({
      href: `/de/${router.asPath?.split("?")[0]}`,
      query: updatedQuery,
    });
  };
  // Change department and competence data to work with CMS
  return (
    <>
      <div className="mt-5 mb-3">
        {formatMessage({
          id: "filter_by",
          defaultMessage: "Filtern nach:",
        })}
      </div>
      <div className="select-wrap mr-2 mb-2">
        <CompetenceDropdown
          onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
            updateRoute("competence", event.target.value);
          }}
          value={router.query?.competence || ""}
        />
      </div>
      <div className="select-wrap">
        <DepartmentDropdown
          onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
            updateRoute("department", event.target.value);
          }}
          value={router.query?.department || ""}
        />
      </div>
    </>
  );
};

export default TeamFilters;
