import React from "react";
import Icon from "../../common/components/Icon";
import useCategories from "../hooks/useCategories";
import CategoryListItem from "./CategoryListItem";

const CategoryList = ({ children, tags = null }) => {
  const { categories = [], loading } = useCategories({
    tags,
  });
  return (
    <div className="row">
      {categories?.map((entry) => (
        <CategoryListItem
          key={entry._id}
          categoryData={entry}
          className="col-md-6 col-lg-4 hero-product-col"
        />
      ))}
      {children}
      {loading ? <Icon className="loader-wrap" icon="loading" /> : ""}
    </div>
  );
};

export default CategoryList;
