import { useRouter } from "next/router";
import { useState } from "react";

// eslint-disable-next-line
const useFakeLoadMore = (initialOffset = 1) => {
  const router = useRouter();
  const [offset, setOffset] = useState(initialOffset);

  const loadMore = () => {
    const newOffset = offset + 1;
    router.replace(
      {
        hash: newOffset.toString(),
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      },
    );
    setOffset(newOffset);
  };

  const shouldShowLoadMore = (length) => {
    if (length === undefined || length === null) return false;
    if (length > offset) return true;
    return false;
  };
  return { offset, loadMore, shouldShowLoadMore };
};

export default useFakeLoadMore;
