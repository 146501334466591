import H1Tag from "../../../layout/components/dom-parse-components/H1Tag";
import H2Tag from "../../../layout/components/dom-parse-components/H2Tag";
import H3Tag from "../../../layout/components/dom-parse-components/H3Tag";
import H4Tag from "../../../layout/components/dom-parse-components/H4Tag";
import H5Tag from "../../../layout/components/dom-parse-components/H5Tag";
import H6Tag from "../../../layout/components/dom-parse-components/H6Tag";

const RenderHeadingLevel = ({ children, className, level }) => {
  switch (level) {
    case "1":
      return <H1Tag className={className}>{children}</H1Tag>;
    case "2":
      return <H2Tag className={className}>{children}</H2Tag>;
    case "3":
      return <H3Tag className={className}>{children}</H3Tag>;
    case "4":
      return <H4Tag className={className}>{children}</H4Tag>;
    case "5":
      return <H5Tag className={className}>{children}</H5Tag>;
    case "6":
      return <H6Tag className={className}>{children}</H6Tag>;
    default:
      return <H1Tag className={className}>{children}</H1Tag>;
  }
};
const HeadingComponent = ({ data }) => {
  return (
    <RenderHeadingLevel className={`${data?.class || ""}`} level={data.level}>
      {data.text}
    </RenderHeadingLevel>
  );
};

export default HeadingComponent;
