import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import useCurrentUser from "../../auth/hooks/useCurrentUser";
import usePopup from "../hooks/usePopup";
import Icon from "../../common/components/Icon";
import ImageWithFallback from "modules/common/components/ImageWithFallback";

const displayToCurrentUser = (isPrivate, displayTo, pathname) => {
  if (
    (isPrivate === null || isPrivate === undefined) &&
    displayTo?.includes("anonymous-home") &&
    pathname === "/"
  )
    return true;
  if (isPrivate && displayTo?.includes("private")) return true;
  if (!isPrivate && displayTo?.includes("institution")) return true;
  return false;
};

export const RawPopup = ({ onClose, popup }) => {
  const intl = useIntl();

  return (
    <div className="popup-overlay" onClick={onClose} aria-hidden="true">
      <div className="popup-advert">
        <button
          className="no-button popup-advert-close"
          type="button"
          aria-label={intl.formatMessage({
            id: "close",
            defaultMessage: "Schliessen",
          })}
          onClick={onClose}
        >
          <Icon className="icon--small" icon="close" fill="currentColor" />
          <span className="ml-3 hide-on-not-mobile hide-on-mobile">
            {intl.formatMessage({ id: "close", defaultMessage: "Schliessen" })}
          </span>
        </button>

        <a href={popup.link} target="_blank" rel="noreferrer">
          {popup.image?.path && (
            <ImageWithFallback
              className="mt-0 force-position-relative"
              src={popup.image?.path}
              alt={popup.image?.title}
              quality={80}
              fill
              sizes="100vw"
              style={{
                objectFit: "contain",
              }}
            />
          )}
        </a>
      </div>
    </div>
  );
};

const Popup = () => {
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(false);
  const { currentUser, loading: loadingUser } = useCurrentUser();
  const { popup, loading: loadingPopup } = usePopup({
    isPrivate: currentUser?.self?.isPrivateCustomer,
    skip: loadingUser,
  });

  useEffect(() => {
    if (popup && !loadingUser && !loadingPopup) {
      const popupAlreadyShown = window.localStorage.getItem(
        `popup-displayed-${popup?._id}`,
      );
      const popupCanBeShown = displayToCurrentUser(
        currentUser?.self?.isPrivateCustomer,
        popup.displayTo,
        router?.asPath,
      );
      setIsVisible(!popupAlreadyShown && popupCanBeShown);
    }
  }, [popup, currentUser, loadingUser, loadingPopup, router]);

  if (!isVisible || !popup) return null;

  const closePopup = () => {
    window.localStorage.setItem(`popup-displayed-${popup._id}`, "true");
    setIsVisible(false);
  };

  return <RawPopup onClose={closePopup} popup={popup} />;
};

export default Popup;
