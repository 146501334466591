import ImageWithFallback from "modules/common/components/ImageWithFallback";

const ImageComponent = ({ data, label }) => {
  return (
    <figure className={`m-0 ${data?.class || ""}`}>
      <ImageWithFallback
        className="mt-0 force-position-relative"
        src={data?.asset?.path}
        alt={data?.asset?.description || data?.asset?.title}
        quality={80}
        fill
        sizes="100vw"
        style={{
          objectFit: "contain",
        }}
      />
      <figcaption className="d-none">{label}</figcaption>
    </figure>
  );
};

export default ImageComponent;
