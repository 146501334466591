import { useQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";

export const CMSAdvantages = gql`
  query cmsAdvantages($lang: String) {
    advantagesModel(locale: $lang) {
      _id
      title
      text
      label
    }
  }
`;

const useCMSAdvantages = () => {
  const intl = useIntl();
  const lang = intl.locale.split("-")[0];

  const { loading, error, data } = useQuery(CMSAdvantages, {
    variables: { lang },
  });

  const { advantagesModel } = data || {};

  return {
    loading,
    error,
    advantages: advantagesModel || [],
  };
};

export const preFetchCMSAdvantages = async (apolloClient, { locale }) => {
  const { data } = await apolloClient.query({
    query: CMSAdvantages,
    variables: { lang: locale },
  });
  return data;
};

export default useCMSAdvantages;
