import ImageWithFallback from "modules/common/components/ImageWithFallback";

const Hero = ({ image, content }) => {
  return (
    <div className="mb-5 -mt-3">
      <div className="hero-image-wrap overflow-revert">
        <ImageWithFallback
          src={image.path}
          className="br-9 hero-image"
          alt={image.title}
          quality={80}
          layout="fill"
          sizes="100vw"
          style={{
            objectFit: "cover",
          }}
        />
      </div>
      <div className="position-relative">
        <div
          className="hero-text box-shadow color-bg-blue-90"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

export default Hero;
