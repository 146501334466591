const normalizeDateFormat = (fieldName) => (field) => {
  const date = field?.[fieldName];
  if (date?.[4] === "-") {
    return {
      ...field,
      [fieldName]: `${date.substring(8, 10)}.${date.substring(
        5,
        7,
      )}.${date.substring(0, 4)}`,
    };
  }
  return field;
};

export default normalizeDateFormat;
