import classNames from "classnames";

const TFootTag = ({ children, className = "", ...props }) => {
  return (
    <tfoot className={classNames("tfoot", className)} {...props}>
      {children}
    </tfoot>
  );
};

export default TFootTag;
