import { gql, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

const GET_SYSTEM_STATUS = gql`
  query cmsGetSystemStatus($lang: String, $filter: JsonType) {
    systemstatusModel(locale: $lang, filter: $filter, sort: { _o: 1 }) {
      _id
      _modified
      title
      text
      status
      link
    }
  }
`;

const useCMSSystemStatusByIds = (systemStatusIds?: string[]) => {
  const intl = useIntl();
  const lang = intl.locale.split("-")[0];

  const filter: any = systemStatusIds
    ? {
        _id: { $in: systemStatusIds },
      }
    : {};

  const { data, error, loading } = useQuery(GET_SYSTEM_STATUS, {
    variables: {
      lang,
      filter,
    },
  });

  return {
    loading,
    error,
    status: data?.systemstatusModel || [],
  };
};

export default useCMSSystemStatusByIds;
